<template>
  <m-drop-down class="hdr-main-menu" :items="items" @click="onItemClick">
    <b-hdr-menu slot="button" />
  </m-drop-down>
</template>

<script>
import DropDownMenu from '../../menu/dropDownMenu'
import HeaderMenuButton from '../../button/headerMenuButton'
import MenuMixin from '../../../mixins/menu'

export default {
  components: {
    'b-hdr-menu': HeaderMenuButton,
    'm-drop-down': DropDownMenu,
  },
  computed: {
    /**
     * Header menu items
     */
    items() {
      return [...this.altLocaleItems, ...this.settingsItems, this.logoutItem]
    },
  },
  data() {
    return {
      /**
       * Event source
       */
      eventSource: 'header.menu',
    }
  },
  mixins: [MenuMixin],
}
</script>

<style lang="postcss" scoped>
.m.m--dropdown {
  & >>> .m__list {
    @apply left-auto right-0;
    width: 200px;
  }
}
.m.m--dropdown:hover {
  & >>> .b__arrow {
    transform: rotate(180deg);
  }
}
</style>
