const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['editJob'] = require('../middleware/editJob.js')
middleware['editJob'] = middleware['editJob'].default || middleware['editJob']

middleware['postJob'] = require('../middleware/postJob.js')
middleware['postJob'] = middleware['postJob'].default || middleware['postJob']

middleware['profile'] = require('../middleware/profile.js')
middleware['profile'] = middleware['profile'].default || middleware['profile']

middleware['setLocale'] = require('../middleware/setLocale.js')
middleware['setLocale'] = middleware['setLocale'].default || middleware['setLocale']

middleware['suspended'] = require('../middleware/suspended.js')
middleware['suspended'] = middleware['suspended'].default || middleware['suspended']

export default middleware
