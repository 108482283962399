<template>
  <not-found-page />
</template>

<script>
import DeferInitMixin from '../mixins/layout/deferInit'
import NotFoundPage from '../pages/404'

export default {
  components: {
    'not-found-page': NotFoundPage,
  },
  layout: 'popup',
  mixins: [DeferInitMixin],
  name: 'ErrorLayout',
  props: {
    /**
     * Error data provided by Nuxt,
     * ref: https://nuxtjs.org/guide/views#error-page
     */
    error: {
      default: () => ({}),
      type: Object,
    },
  },
}
</script>
