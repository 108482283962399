<template>
  <li
    class="side-nav-item"
    :class="isItemCurrent ? 'side-nav-item--current' : ''"
  >
    <b-icon-text
      :active="active"
      :link-path="linkPath"
      :href="href"
      :link-query="linkQuery"
      :title="title"
    >
      <component :is="icon" slot="icon" class="svg-fill" />
      <h5 v-if="count" slot="label" class="side-nav-item--count">
        {{ count }}
      </h5>
      <h5 v-else-if="newFeature" slot="label" class="side-nav-item--new">
        {{ $t('jobPromotion.newFeature') }}
      </h5>
    </b-icon-text>
  </li>
</template>

<script>
import IconTextButton from '../button/iconTextButton'
import SvgApplicant from '../../assets/svg/applicant.svg?inline'
import SvgJob from '../../assets/svg/job.svg?inline'
import SvgProfile from '../../assets/svg/profile.svg?inline'

export default {
  components: {
    'b-icon-text': IconTextButton,
    'svg-applicant': SvgApplicant,
    'svg-job': SvgJob,
    'svg-profile': SvgProfile,
  },
  computed: {
    /**
     * Whether menu item is currently visited
     */
    isItemCurrent() {
      return (
        this.linkPath === this.$route.path ||
        (this.childPathPattern &&
          this.$route.path.indexOf(this.childPathPattern) === 0)
      )
    },
  },
  props: {
    /**
     * Whether nav item is active
     */
    active: {
      default: true,
      type: Boolean,
    },

    /**
     * Nav item children path pattern
     */
    childPathPattern: {
      default: '',
      type: String,
    },

    /**
     * Count number
     */
    count: {
      default: '',
      type: String,
    },

    /**
     * External path for Nav item link
     */
    href: {
      default: '',
      type: String,
    },

    /**
     * Nav item icon name
     */
    icon: {
      required: true,
      type: String,
    },

    /**
     * Nav item link path
     */
    linkPath: {
      required: true,
      type: String,
    },

    /**
     * Nav item link query
     */
    linkQuery: {
      default: () => ({}),
      type: Object,
    },

    /**
     * Nav item new feature
     */
    newFeature: {
      default: false,
      type: Boolean,
    },

    /**
     * Nav item title
     */
    title: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.side-nav-item {
  @apply relative;
}

/* Nav item button */
.b.b--icon_text {
  @apply h-full;

  &:after {
    @apply rounded-none bg-grey-400;
  }
}

@screen mobile {
  .side-nav-item {
    @apply h-full;
  }

  .side-nav-item--count {
    @apply absolute top-0 mt-1;
    @apply px-2 py-1 rounded-md rounded-bl-none;
    @apply bg-pink text-white text-xs;
    left: 60%;
  }

  /* Nav item button */
  .b.b--icon_text {
    @apply flex flex-col justify-center text-grey-600;

    & svg {
      @apply mr-0 text-grey-200;
    }
  }

  /* Current nav item */
  .side-nav-item.side-nav-item--current .b.b--icon_text {
    & svg {
      @apply text-black;
    }
    & >>> .b__title {
      @apply text-black;
      font-weight: 500;
    }
    &:after {
      @apply opacity-10;
    }
  }
}

@screen desktop {
  .side-nav-item--new {
    @apply px-2 py-1 ml-2 mb-4 rounded-md rounded-bl-none;
    @apply bg-pink text-white text-xs;
  }

  .side-nav-item--count {
    @apply flex-1 text-right pr-3;
  }

  .side-nav-item {
    @apply w-full;
    height: 48px;
  }

  /* Nav item button */
  .b.b--icon_text {
    @apply pl-9 pr-4;

    &:after {
      width: calc(100% - 0.5rem);
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }

  /* Current nav item */
  .side-nav-item.side-nav-item--current .b.b--icon_text {
    &:after {
      @apply opacity-10;
    }
    & >>> .b__title {
      @apply font-bold;
    }
  }
}
</style>
