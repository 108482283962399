import { addTrailingSlash } from '../libs/metadata'

/**
 * Mixin for localization utilities
 */
export default {
  computed: {
    /**
     * Locales other than current one
     * @returns {Array}
     */
    altLocales() {
      return this.$i18n.locales.filter(
        (locale) => locale && locale.code !== this.$i18n.locale,
      )
    },

    /**
     * Current locale config
     */
    currentLocale() {
      return this.$i18n.locales.find(
        (locale) => locale && locale.code === this.$i18n.locale,
      )
    },
  },
  methods: {
    /**
     * Translate with fallback:
     * translate if *prefix + key* keypath exists,
     * otherwise return key
     * @param {string} prefix Locale keypath prefix
     * @param {string} key Locale keypath key
     * @returns {string}
     */
    $tf(prefix, key) {
      const keypath = `${prefix}['${key}']`
      if (this.$te(keypath)) {
        return this.$t(keypath)
      }
      return key
    },

    /**
     * Wrapper function for i18n *localePath()*,
     * accepting string paths
     * @param {string} path
     */
    localePathName(path = '') {
      return this.localePath({
        // Enforce trailing slash in all routes,
        // ref: https://hreflang.org/trailing-slashes-in-urls-seo-implications/
        path: addTrailingSlash(path),
      })
    },

    /**
     * Wrapper function for setting i18n locale
     * @param {string} locale
     * @param {string} eventSource
     */
    setAppLocale(locale, eventSource) {
      // Set i18n locale
      this.$i18n.setLocale(locale)

      // Send update language event
      this.$eventTracker.sendCustomEvent(
        'update_display_language',
        {
          event_source: eventSource,
          locale,
        },
        ['event_source'],
      )

      // Set app language in tracker context
      this.$eventTracker.setLanguage(locale)
    },
  },
}
