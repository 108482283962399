import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'

/**
 * Inject Vue Google maps plugin
 */
Vue.use(VueGoogleMaps, {
  /**
   * Manually install components
   */
  installComponents: false,

  load: {
    /**
     * Google maps API key
     */
    key: process.env.EMPLOYER_WEB__GMAPS_KEY,

    /**
     * Skip import additional libraries
     */
    libraries: '',

    /**
     * Google maps API version,
     * ref: https://developers.google.com/maps/documentation/javascript/releases
     */
    v: '3.42',
  },
})
