/**
 * Check if provided path is post job route
 * @param {string} path
 */
const isPostJobRoute = (path) => {
  return path.indexOf('/jobs/post/') === 0
}

/**
 * Check if provided path is edit job route
 * @param {string} path
 */
const isEditJobRoute = (path) => {
  return path.indexOf('/jobs/edit/') === 0
}

/**
 * Check if provided path is copy job route
 * @param {string} path
 */
const isCopyJobRoute = (path) => {
  return path.indexOf('/jobs/copy/') === 0
}

/**
 * Clear edit job and profile data,
 * and reset post job progress
 */
const resetEditJob = (store) => {
  // Clear edit job and profile data
  store.commit('editJob/resetEditJobData')
  store.commit('editJob/resetEditProfileData')

  // Reset post job progress
  store.commit('editJob/resetStepsCompleted')
}

/**
 * NuxtJS middleware to clear edit job data
 * on leaving post/edit job pages
 */
export default function ({ from, route, store }) {
  const isExistingRoutePostJob = isPostJobRoute(from.path)
  const isExistingRouteEditJob = isEditJobRoute(from.path)
  const isExistingRouteCopyJob = isCopyJobRoute(from.path)
  const isExistingRouteJobForm =
    isExistingRoutePostJob || isExistingRouteEditJob || isExistingRouteCopyJob

  // If existing route is post/edit job,
  // check if need to clear edit job and profile data
  if (isExistingRouteJobForm) {
    const isTargetRoutePostJob = isPostJobRoute(route.path)
    const isTargetRouteEditJob = isEditJobRoute(route.path)
    const isTargetRouteCopyJob = isCopyJobRoute(route.path)
    const isTargetRouteJobForm =
      isTargetRoutePostJob || isTargetRouteEditJob || isTargetRouteCopyJob

    // 1) Target route isn't post/edit job
    //    (i.e. leaving post/edit job)
    if (!isTargetRouteJobForm) {
      resetEditJob(store)
    }

    // 2) Target route is post job
    if (isTargetRoutePostJob) {
      // 2.a) Existing route is edit job
      //      (i.e. clicking post job button while editing job)
      if (isExistingRouteEditJob) {
        resetEditJob(store)
      }

      // 2.b) Existing route is also post job
      if (isExistingRoutePostJob) {
        // 2.b.i) Existing route has different employment type
        //        (i.e. changing employment type)
        if (from.query.et !== route.query.et) {
          resetEditJob(store)
        }

        // 2.b.ii) Existing route is post job success
        //         (i.e. posting another job)
        if (from.query.posted === 'true') {
          resetEditJob(store)
        }
      }

      // 2.c) Existing route is copy job success
      //      (i.e. posting another job)
      if (isExistingRouteCopyJob && from.query.posted === 'true') {
        resetEditJob(store)
      }
    }
  }
}
