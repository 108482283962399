/**
 * Deferred initialisation for layouts
 */
export default {
  mounted() {
    // Handle initialisation after page is mounted
    this.$nextTick(() => {
      setTimeout(() => {
        this.$eventTracker.deferInit()
      }, 1000)
    })
  },
}
