<template>
  <component
    :is="buttonComponent"
    class="b b--def"
    tabindex="0"
    v-bind="buttonProps"
    @click="onClick"
  >
    <span class="b__title">{{ title }}</span>
  </component>
</template>

<script>
import ButtonMixin from '../../mixins/button'

export default {
  mixins: [ButtonMixin],
}
</script>

<style lang="postcss" scoped>
.b.b--def {
  @apply px-7;
}

@screen desktop {
  .b.b--def {
    @apply px-10;
  }
}
</style>
