/**
 * NuxtJS middleware to redirect user to
 * company suspended page
 */
export default function ({ redirect, route, store }) {
  // Whether company is suspended
  const companySuspended = store.getters['profile/companySuspended']

  // Whether target route is company suspended page
  const isSuspendedRoute = route.path === '/suspended/'

  // Whether target route is whitelisted for access
  const isWhitelistedRoute = [
    '/profile/',
    '/profile/public/',
    '/profile/internal/',
    '/account/',
    '/account/verify/',
    '/billing/payment-methods/',
    '/billing/payment-methods/create/',
    '/billing/invoices/',
  ].includes(route.path)

  if (companySuspended && !isSuspendedRoute && !isWhitelistedRoute) {
    // Redirect to company suspended page if:
    // 1) company suspended, and
    // 2.a) target route is not suspended page, and
    // 2.b) target route is not whitelisted for access
    redirect('/suspended/')
  } else if (!companySuspended && isSuspendedRoute) {
    // Redirect to home page if:
    // 1) company not suspended, and
    // 2) target route is suspended page
    redirect('/')
  }
}
