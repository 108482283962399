<template>
  <component
    :is="buttonComponent"
    class="b b--menu"
    tabindex="0"
    v-bind="buttonProps"
    @click="onClick"
  >
    <span class="b__title">{{ title }}</span>
    <slot name="label" />
  </component>
</template>

<script>
import ButtonMixin from '../../mixins/button'

export default {
  mixins: [ButtonMixin],
}
</script>

<style lang="postcss" scoped>
.b.b--menu {
  @apply justify-start px-4;
  height: 2.5rem;

  & .b__title {
    @apply text-grey-900;
  }
}

@screen desktop {
  .b.b--menu {
    @apply px-5;
    height: 3rem;
  }
}
</style>
