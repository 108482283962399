/* eslint-disable quote-props */

/**
 * Screen name based on route name and query
 */
export const screenNameMap = {
  account: 'account',
  applications: 'applications',
  'billing-invoices': 'billing.invoices.job_promotion',
  'billing-payment-methods': 'billing.payment_methods',
  'billing-payment-methods-create': 'billing.payment_methods.create',
  index: 'home',
  'jobs-copy': 'copy_job',
  'jobs-detail': 'job_detail',
  'jobs-edit-attributes': 'edit_job.attributes',
  'jobs-edit-contact': 'edit_job.contact_methods',
  'jobs-edit-info': 'edit_job.basic_info',
  'jobs-edit-locations': 'edit_job.addresses',
  'jobs-edit-requirements': 'edit_job.requirements',
  'jobs-edit-salary-benefits': 'edit_job.salary_benefits',
  'jobs-edit-shifts': 'edit_job.shifts',
  'jobs-post-attributes': 'post_job.attributes',
  'jobs-post-contact': 'post_job.company_info',
  // FEATURE_JOB_CONTACTS 'jobs-post-contact': 'post_job.contact_methods',
  'jobs-post-info': 'post_job.basic_info',
  'jobs-post-locations': 'post_job.addresses',
  'jobs-post-requirements': 'post_job.requirements',
  'jobs-post-salary-benefits': 'post_job.salary_benefits',
  'jobs-post-shifts': 'post_job.shifts',
  'jobs-promotion': 'job_promotion.detail',
  'jobs-promotion-create': 'job_promotion.create',
  'jobs-promotion-create-payment-method': 'job_promotion.create_payment_method',
  'jobs-promotion-performance': 'job_promotion.performance',
  login: 'login',
  'login-login-mfa': 'login.mfa',
  'password-email-sent': 'password.forgot_email_sent',
  'password-forgot': 'password.forgot',
  'password-reset': 'password.reset',
  'password-set': 'signup.set_password',
  profile: 'profile.mobile_menu',
  'profile-create': 'signup.create_profile',
  'profile-internal': 'profile.internal_info',
  'profile-locations': 'profile.addresses',
  'profile-public': 'profile.public_info',
  'profile-upload-br': 'signup.upload_br',
  'profile-upload-logo': 'signup.upload_logo',
  signup: 'signup',
  'signup-email-sent': 'signup.email_sent',
  'signup-signup-mfa': 'signup.mfa',
  'signup-verify': 'signup.verify',
  suspended: 'account_suspended',
}

/**
 * Extract screen name
 * based on provided route object
 */
export function getScreenName(route) {
  // Get screen name based on route name,
  // fallback to route name
  if (route && route.name) {
    return screenNameMap[route.name] || route.name
  }
  // Otherwise return not found screen
  return 'not_found'
}
