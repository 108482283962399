import _pick from 'lodash/pick'

/**
 * Return list of post/edit job step paths
 * based on provided route query
 */
export function getPostJobSteps(route, isEdit = false) {
  if (route) {
    // Set path based on post or edit job
    const pathPrefix = `/jobs/${isEdit ? 'edit' : 'post'}/`

    // Pass required route queries
    const query = _pick(route.query, [
      '_id',
      'copy',
      'et',
      'event_source',
      'target',
    ])

    return [
      {
        name: 'basic_info',
        path: `${pathPrefix}info/`,
        query,
        titleKey: 'postJob.basicInfoStep',
      },
      {
        name: 'salary_benefits',
        path: `${pathPrefix}salary-benefits/`,
        query,
        titleKey: 'postJob.salaryBenefitsStep',
      },
      {
        inactive: isEdit,
        name: 'addresses',
        path: `${pathPrefix}locations/`,
        query,
        titleKey: 'postJob.locationsStep',
      },
      {
        name: 'shifts',
        path: `${pathPrefix}shifts/`,
        query,
        titleKey: 'postJob.shiftsStep',
      },
      {
        name: 'requirements',
        path: `${pathPrefix}requirements/`,
        query,
        titleKey: 'postJob.requirementsStep',
      },
      // FEATURE_JOB_ATTRS {
      //   name: 'attributes',
      //   path: `${pathPrefix}attributes/`,
      //   query,
      //   titleKey: 'postJob.attributesStep',
      // },
      {
        inactive: isEdit, // FEATURE_JOB_CONTACTS
        name: 'contact_methods',
        path: `${pathPrefix}contact/`,
        query,
        // FEATURE_JOB_CONTACTS titleKey: 'postJob.contactInfoStep',
        titleKey: 'postJob.companyInfoStep',
      },
    ]
  }

  return []
}

/**
 * Find index of step from list of steps
 * based on provided step path
 * @param {Array} steps
 * @param {string} path
 */
export function getStepIndexByPath(steps, path) {
  if (Array.isArray(steps)) {
    return steps.findIndex((step) => step && step.path === path)
  }
  return -1
}
