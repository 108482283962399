/**
 * Auth *Vuex* module state
 */
export default function () {
  return {
    /**
     * Token payload
     */
    payload: null,

    /**
     * Access token
     */
    token: '',
  }
}
