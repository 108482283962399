import errorKeys from '../../libs/errorKeys'
import LogoutMixin from '../auth/logout'

/**
 * Mixin for form validation
 */
export default {
  computed: {
    /**
     * Check if all the form fields are valid
     */
    fieldsValid() {
      return Object.keys(this.fields).reduce((areFieldsValid, fieldName) => {
        const field = this.fields[fieldName]
        const isFieldExist = !!field
        if (!areFieldsValid) {
          return false
        }
        if (!isFieldExist) {
          return areFieldsValid
        }
        // We should skip checking error in optional but unfilled field
        return (
          (field.optional && this.isFieldUnFilled(field.value)) || !field.error
        )
      }, true)
    },

    /**
     * The form is valid only when
     * 1) it's been updated, and
     * 2) all its fields are valid
     */
    formValid() {
      return this.formUpdated && this.fieldsValid
    },
  },
  created() {
    // Toggle page loading state
    this.togglePageLoading(this.formLoading)

    // Initialize form fields
    this.initFields()
  },
  data() {
    return {
      /**
       * All the form fields.
       * Each field requires the structure
       * *{ triggers: [string], validator: function, value: any }*
       */
      fields: {},

      /**
       * Form error/message title
       * for triggering snackbar alert display
       */
      formAlertTitle: '',

      /**
       * Form error key
       */
      formError: '',

      /**
       * Whether the form is being submitted
       */
      formLoading: false,

      /**
       * Form message key
       */
      formMsg: '',

      /**
       * Whether the form has been updated
       */
      formUpdated: false,
    }
  },
  methods: {
    /**
     * Return localized field error message
     * if field updated and error key available
     * @param {string} fieldName
     */
    getFieldError(fieldName) {
      const field = this.fields[fieldName]
      const isFieldExist = !!field
      const isFieldOptionalAndUnfilled =
        field.optional && this.isFieldUnFilled(field.value)
      if (!isFieldExist || !field.updated) {
        return ''
      }
      // We should not show error in optional but unfilled field
      if (field.error && !isFieldOptionalAndUnfilled) {
        return this.$t(`error['${field.error}']`)
      }
      return ''
    },

    /**
     * Return localized number field error message
     * if field updated and error key available,
     * supports number limits and step value injection
     * based on provided number field config
     * @param {string} fieldName
     */
    getNumberFieldError(fieldName, fieldConfig) {
      const field = this.fields[fieldName]
      if (field && field.updated && field.error) {
        // Find default value from field config
        // based on error key
        let n
        if (fieldConfig) {
          switch (field.error) {
            case errorKeys.NUMBER_NOT_FOLLOW_STEP:
              if (Number.isFinite(fieldConfig.step)) {
                n = fieldConfig.step
              }
              break
            case errorKeys.NUMBER_BELOW_MIN:
              if (
                fieldConfig.limits &&
                Number.isFinite(fieldConfig.limits.min)
              ) {
                n = fieldConfig.limits.min
              }
              break
            case errorKeys.NUMBER_EXCEED_MAX:
              if (
                fieldConfig.limits &&
                Number.isFinite(fieldConfig.limits.max)
              ) {
                n = fieldConfig.limits.max
              }
              break
          }
        }

        // If default value available,
        // inject into error message
        if (n) {
          return this.$t(`error.${field.error}`, { n })
        }
        return this.$t(`error['${field.error}']`)
      }
      return ''
    },

    /**
     * Return localized time string field error message
     * if field updated and error key available,
     * supports step value injection based on provided config
     * @param {string} fieldName
     */
    getTimeFieldError(fieldName, fieldConfig) {
      const field = this.fields[fieldName]
      if (field && field.updated && field.error) {
        // If default value available,
        // inject into error message
        if (
          field.error === errorKeys.TIME_STR_NOT_FOLLOW_STEP &&
          fieldConfig &&
          fieldConfig.step >= 60
        ) {
          return this.$t(`error.${field.error}`, {
            n: fieldConfig.step / 60,
          })
        }
        return this.$t(`error['${field.error}']`)
      }
      return ''
    },

    /**
     * Handle generic form errors
     */
    handleFormErrors({ payload = {}, status } = {}, skip401Validation = false) {
      if (status === 401 && !skip401Validation) {
        // Token invalid (unauthorized)
        this.formError = errorKeys.E200011

        // Force-logout user after delay
        setTimeout(() => {
          this.logout('force_logout')
        }, this.forceLogoutDelay)
      } else if (payload.error_code) {
        // REST endpoint error codes
        const errorKey = `E${payload.error_code}`
        this.formError = errorKeys[errorKey] || errorKey
      } else if (!status || status >= 500) {
        // Network / backend error
        this.formError = errorKeys.REQUEST_ERROR
      }
    },

    /**
     * Initialize error key and updated flag
     * for each form field
     */
    initFields() {
      Object.keys(this.fields).forEach((fieldName) => {
        const field = this.fields[fieldName]
        if (field) {
          // Set field updated flag and error key
          // as new reactive properties,
          // ref: https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
          this.$set(field, 'updated', false)
          this.$set(field, 'error', '')

          // Initialize field error key
          this.validateField(field)
        }
      })
    },

    /**
     * Check whether the field is required,
     * i.e. field validator function provided,
     * based on provided field name
     * @param {string} fieldName
     */
    isFieldRequired(fieldName) {
      const field = this.fields[fieldName]
      return field && typeof field.validator === 'function' && !field.optional
    },

    /**
     * Check whether the field is unfilled
     */
    isFieldUnFilled(fieldValue) {
      // eslint-disable-next-line no-undefined
      return (
        fieldValue === null ||
        fieldValue === '' ||
        fieldValue === undefined ||
        Number.isNaN(fieldValue)
      )
    },

    /**
     * Validate form field and
     * update form updated flag
     * based on provided field name
     * @param {string} fieldName
     */
    onFieldInput(fieldName) {
      if (this.fields[fieldName]) {
        const field = this.fields[fieldName]

        // Set field updated flag to true
        field.updated = true
        // Try to validate form field
        this.validateField(field)

        // Trigger validating dependent fields if available
        if (Array.isArray(field.triggers)) {
          field.triggers.forEach((triggerFieldName) => {
            if (this.fields[triggerFieldName]) {
              this.validateField(this.fields[triggerFieldName])
            }
          })
        }

        // Set form updated flag to true
        this.formUpdated = true

        // Reset form error and message keys
        this.resetFormErrorMsg()
      }
    },

    /**
     * Handle form alert show event
     */
    onFormAlertShow() {
      // Reset form alert title
      this.formAlertTitle = ''
    },

    /**
     * Reset form error and message keys
     */
    resetFormErrorMsg() {
      this.formError = ''
      this.formMsg = ''
    },

    /**
     * Wrapper function for submitting form,
     * adds form validation guard and handles generic errors
     * @param {function} submitFunc
     */
    async submitForm(
      submitFunc,
      { skipValidation = false, skip401Validation = false } = {},
    ) {
      // Only try to submit form
      // when form is valid or validation skipped
      // and form not loading
      if (
        (this.formValid || skipValidation) &&
        !this.formLoading &&
        typeof submitFunc === 'function'
      ) {
        // Set form loading flag to *true*
        this.formLoading = true

        // Reset form error and message keys
        this.resetFormErrorMsg()

        // Execute submit function and await for result
        const res = await submitFunc()

        // Handle generic errors
        if (res && res.error) {
          this.handleFormErrors(res.error, skip401Validation)
        }

        // Unset form loading flag
        this.formLoading = false

        // Return result
        return res
      }
    },

    /**
     * Toggle *vuex* page loading state
     */
    togglePageLoading(isLoading = false) {
      this.$store.commit('loading/toggleLoading', isLoading)
    },

    /**
     * Try to validate provided form field
     * by the validator function
     */
    validateField(field) {
      if (field) {
        if (typeof field.validator === 'function') {
          // If field validator function provided,
          // validate field value w/ the function
          field.error = field.validator(field.value) || ''
        } else {
          // On default set empty error key
          field.error = ''
        }
      }
    },
  },
  mixins: [LogoutMixin],
  watch: {
    /**
     * Set form alert title
     * on form error key update
     */
    formError(newVal) {
      if (newVal) {
        this.formAlertTitle = this.$t(`error.${newVal}`)
      }
    },

    /**
     * Toggle page loading state
     * on form loading state update
     */
    formLoading(isLoading) {
      this.togglePageLoading(isLoading)
    },

    /**
     * Set form alert title
     * on form message key update
     */
    formMsg(newVal) {
      if (newVal) {
        this.formAlertTitle = this.$t(`message.${newVal}`)
      }
    },
  },
}
