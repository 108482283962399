import { injectScript } from '../utils'
import { requestIdleCallback } from '../../timeout'
import Tracker from '.'

/**
 * Facebook Analytics tracker,
 * uses *facebook SDK* for sending tracking events,
 * ref: https://developers.facebook.com/docs/javascript/
 */
export default class FbAnalyticsTracker extends Tracker {
  /**
   * @param {string} id
   * @param {string} version
   */
  constructor(id, version) {
    super(id)
    this._initQueue = []
    this._init = false

    // Set facebook SDK async initialization callback
    window.fbAsyncInit = () => {
      if (
        typeof window.FB !== 'undefined' &&
        typeof window.FB.init === 'function'
      ) {
        // Initialize facebook SDK
        window.FB.init({
          appId: this._id,
          version: 'v6.0',
          xfbml: true,
        })

        // Execute callbacks in initialization queue
        requestIdleCallback(() => {
          this._initQueue.forEach((callback) => {
            if (typeof window.FB.AppEvents !== 'undefined') {
              callback()
            }
          })
        })
        this._init = true
      }
    }

    // Set app version
    this.setAppVersion(version)
  }

  /**
   * Handle deferred initialisation
   */
  deferInit() {
    // Only initialize once
    if (!this._deferInitDone) {
      injectScript('https://connect.facebook.net/en_US/sdk.js', true)
      super.deferInit()
    }
  }

  /**
   * Send facebook analytics custom event
   * @param {string} eventName
   */
  sendCustomEvent(eventName, eventVariables) {
    this.triggerFBCallback(() => {
      window.FB.AppEvents.logEvent(eventName, null, eventVariables)
    })
  }

  /**
   * Send facebook analytics pageview event
   * @param {string} screenName
   * @param {string} pageLocation
   * @param {string} pagePath
   */
  sendPageViewEvent(screenName, pageLocation, pagePath) {
    // Send page view event
    this.triggerFBCallback(() => {
      window.FB.AppEvents.logPageView()
    })

    // Send screen view event
    if (screenName) {
      this.sendCustomEvent('screen_view', {
        page_location: pageLocation,
        page_path: pagePath,
        page_title: screenName,
        screen_name: screenName,
      })
    }
  }

  /**
   * Set facebook analytics app version
   * @param {string} version
   */
  setAppVersion(version) {
    this.triggerFBCallback(() => {
      window.FB.AppEvents.setAppVersion(version)
    })
  }

  /**
   * Set facebook analytics user ID
   * @param {string} userId
   */
  setUserId(userId) {
    this.triggerFBCallback(() => {
      window.FB.AppEvents.setUserID(userId || 'anonymous')
    })
  }

  /**
   * Handle triggering facebook SDK callback
   * with support to async initialization
   * @param {Function} callback
   */
  triggerFBCallback(callback) {
    if (this._init) {
      // If async initialization done,
      // execute callback directly
      // when browser is idle
      requestIdleCallback(callback)
    } else {
      // Otherwise add callback to queue
      this._initQueue.push(callback)
    }
  }
}
