import { decodeToken, isEmployerToken, LS_TOKEN_KEY } from '../../libs/token'
import { removeLocalStorage, setLocalStorage } from '../../libs/localStorage'
import { configureScope } from '@sentry/browser'

/**
 * Auth *Vuex* module actions
 */
export default {
  /**
   * Validate provided employer token,
   * if valid set it to *Vuex* store and *local storage*,
   * then get user profile from Octo *graphql* endpoint,
   * otherwise clear token and profile from the stores
   * @returns {*} Fetched user profile
   */
  async setEmployerToken({ commit, dispatch }, token = '') {
    // Try to decode and validate token
    const payload = decodeToken(token)

    // If token is valid and is **employer-only**,
    // set token to *Vuex* store and *local storage*,
    // otherwise clear from the stores
    if (isEmployerToken(payload)) {
      // Set token and payload to store
      await commit('setTokenAndPayload', {
        payload,
        token,
      })

      // Persist token to local storage
      setLocalStorage(LS_TOKEN_KEY, token)

      // Add user ID to sentry
      configureScope((scope) => {
        scope.setUser({ id: payload.sub })
      })

      // Get user profile from Octo *graphql* endpoint
      return dispatch('profile/getProfile', token, { root: true })
    } else {
      // Clear token and payload from store
      await commit('setTokenAndPayload')

      // Remove token from local storage
      removeLocalStorage(LS_TOKEN_KEY)

      // Clear user ID from sentry
      configureScope((scope) => {
        scope.setUser({ id: null })
      })

      // Clear user profile from store
      await commit('profile/clearProfile', null, { root: true })
    }
  },
}
