<template>
  <div class="app popup-bg" :style="popupBackground">
    <!-- Header -->
    <app-header />
    <!-- Page content -->
    <nuxt class="page page-popup" />
    <!-- Loading overlay -->
    <overlay class="app__overlay" top :show="overlay === 'loading'">
      <loading />
    </overlay>
    <!-- IE not supported alert -->
    <overlay
      v-if="isIE"
      class="app__overlay"
      :show="overlay === 'ieNotSupported'"
    >
      <a-ie-not-supported />
    </overlay>
  </div>
</template>

<script>
import AppHeader from '../components/header/appHeader'
import DeferInitMixin from '../mixins/layout/deferInit'
import { isIE } from '../libs/platform'
import LoadingOverlayMixin from '../mixins/overlay/loading'

export default {
  components: {
    'a-ie-not-supported': () =>
      import('../components/alert/ieNotSupportedAlert'),
    'app-header': AppHeader,
  },
  computed: {
    // In mobile, the background image will not be shown,
    // because we set background-size to 0 in the CSS style below
    popupBackground() {
      return {
        backgroundImage: `url('${process.env.EMPLOYER_WEB__S3_PREFIX}img/wall_pattern.svg')`,
      }
    },
  },
  created() {
    // If browser is IE,
    // show IE not support alert
    if (isIE) {
      this.overlay = 'ieNotSupported'
    }
  },
  data() {
    return {
      isIE,
    }
  },
  mixins: [DeferInitMixin, LoadingOverlayMixin],
  name: 'PopupLayout',
}
</script>

<style lang="postcss" scoped>
.popup-bg {
  background-size: 0;
}

@screen desktop {
  .overlay .load {
    @apply pointer-events-none;
  }
  .popup-bg {
    background-size: 500px;
    background-position: center;
  }
}
</style>
