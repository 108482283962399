import NotificationType from './notificationType'

/**
 *  Pre-defined notification data for the top banner
 *
 * [example]
 * export const MarketingSurveyNotification = {
 *  buttonTitle: 'marketingSurvey.action',
 *  infoType: 'announcement',
 *  message: 'marketingSurvey.message',
 *  type: NotificationType.marketingSurveyNotification,
 * }
 */

export const PaymentExpireSoonNotification = {
  buttonLinkPath: '/billing/payment-methods/create/',
  buttonTitle: 'creditCard.updateNow',
  message: 'creditCard.updateCard',
  overlayLinkPath: '/billing/payment-methods/create/',
  type: NotificationType.paymentExpireSoon,
}

export const PaymentIsExpiredNotification = {
  buttonLinkPath: '/billing/payment-methods/create/',
  buttonTitle: 'creditCard.updateNow',
  message: 'creditCard.cardExpired',
  overlayLinkPath: '/billing/payment-methods/create/',
  type: NotificationType.paymentIsExpired,
}

export const PaymentFailedNotification = {
  buttonTitle: 'invoice.payNow',
  message: 'invoice.paymentFailed',
  type: NotificationType.paymentFailed,
}

export const MaintenanceNotification = {
  buttonTitle: 'general.ok',
  message: 'maintenance.scheduledMaintenance',
  type: NotificationType.scheduledMaintenance,
}

export const GeneralNotification = {
  buttonTitle: 'generalNotification.action',
  infoType: 'info',
  message: 'generalNotification.message',
  type: NotificationType.generalNotification,
}

export const SummerJobPromotionNotification = {
  buttonTitle: 'general.ok',
  infoType: 'announcement',
  message: 'summerJobPromotion.message',
  type: NotificationType.summerJobPromotionNotification,
}

export const OdsIsComingNotification = {
  buttonTitle: 'odsBanner.button',
  infoType: 'announcement',
  message: 'odsBanner.message',
  type: NotificationType.odsIsComing,
}
