/**
 * Profile *Vuex* module state
 */
export default function () {
  return {
    /**
     * User accessible companies
     */
    companies: {},

    /**
     * The display language chosen by user
     */
    displayLanguage: null,

    /**
     * User email address
     */
    email: '',

    /**
     * User failed payments
     */
    failedPayments: {},

    /**
     * User payment method
     */
    paymentMethods: {},

    /**
     * User telephone number
     */
    telephone: '',

    /**
     * Number of unread application
     */
    totalUnreadApplicationCount: {},
  }
}
