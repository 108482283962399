<template>
  <section class="hdr-nav">
    <span class="hdr-nav__spacer" />
    <div class="hdr-nav__links">
      <template v-for="(item, i) in items">
        <!-- Link break -->
        <span
          v-if="i > 0"
          :key="`HeaderNavLinkBreak${i}`"
          class="hdr-nav__link-break"
          >|</span
        >
        <!-- Link -->
        <a
          v-if="item.link"
          :key="`HeaderNavLink${i}`"
          class="hdr-nav__link"
          rel="noopener"
          target="_blank"
          :title="item.name"
          :href="item.link"
          >{{ item.name }}</a
        >
        <button
          v-else
          :key="`HeaderNavLink${i}`"
          class="hdr-nav__link"
          type="button"
          tabindex="0"
          :title="item.name"
          @click="item.clickAction"
        >
          {{ item.name }}
        </button>
      </template>
      <template v-if="isLoggedIn">
        <span class="hdr-nav__link-break">|</span>
        <button
          class="hdr-nav__link"
          type="button"
          tabindex="0"
          :title="logoutTitle"
          @click="logout(eventSource)"
        >
          {{ logoutTitle }}
        </button>
      </template>
    </div>
  </section>
</template>

<script>
import { getLinkByKey, LinkKeys } from '../../libs/mapping/link'
import AuthMixin from '../../mixins/auth'

export default {
  computed: {
    items() {
      const defaultItems = [
        {
          link: getLinkByKey(this.currentLocale.code, LinkKeys.seeker),
          name: this.$t('header.jobSearch'),
        },
        {
          link: getLinkByKey(this.currentLocale.code, LinkKeys.employer),
          name: this.$t('header.employerInformation'),
        },
      ]
      const localeList = this.altLocales.map((locale) => {
        return {
          clickAction: () => this.setAppLocale(locale.code, this.eventSource),
          name: locale.name,
        }
      })
      return defaultItems.concat(localeList)
    },
    logoutTitle() {
      return this.$t('logout.actionMain')
    },
  },
  data() {
    return {
      /**
       * Event source
       */
      eventSource: 'header',
    }
  },
  mixins: [AuthMixin],
}
</script>

<style lang="postcss" scoped>
.hdr-nav {
  @apply flex items-center w-full h-h-nav px-2 bg-red-500;
}
.hdr-nav__spacer {
  @apply block flex-1;
}

.hdr-nav__links {
  @apply flex items-center text-white;
}
.hdr-nav__link-break {
  @apply px-1 text-xs;
  margin-top: -0.1rem;
}
.hdr-nav__link {
  @apply text-xs outline-none;
}

@screen desktop {
  .hdr-nav {
    @apply px-3;
  }
}
</style>
