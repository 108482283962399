/**
 * Notification type options for the top banner
 */
export default {
  biddingPromotion: 'bidding_promotion',
  generalNotification: 'general_notification',
  marketingSurveyNotification: 'marketing_survey_notification',
  odsIsComing: 'ods_is_coming',
  paymentExpireSoon: 'payment_expire_soon',
  paymentFailed: 'payment_failed',
  paymentIsExpired: 'payment_is_expired',
  scheduledMaintenance: 'scheduledMaintenance',
  summerJobPromotionNotification: 'summer_job_promotion_notification',
}
