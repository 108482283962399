import { logout } from '../../api/octo/auth'

/**
 * Mixin for logging out user
 */
export default {
  data() {
    return {
      /**
       * Delay for showing UI alert about
       * force-logging out user, in ms
       */
      forceLogoutDelay: 2000,
    }
  },
  methods: {
    /**
     * Submit logout request to Octo *REST* endpoint,
     * clear token and then redirect to login page
     * @param {string} eventSource
     */
    async logout(eventSource, skipRedirect = false) {
      // Show loading overlay
      this.$store.commit('loading/toggleLoading', true)

      // Send logout request to Octo
      await logout(this.$store.state.auth.token)

      // Clear token from *Vuex* store and *local storage*,
      // and clear user profile from *Vuex*
      await this.$store.dispatch('auth/setEmployerToken')

      // Send logout event
      this.$eventTracker.sendCustomEvent(
        'logout',
        {
          event_source: eventSource,
        },
        ['event_source'],
      )

      if (!skipRedirect) {
        // If skip redirection flag not set,
        // redirect to login page
        this.$router.push('/login/')
      } else {
        // Otherwise hide loading overlay
        this.$store.commit('loading/toggleLoading', false)
      }
    },
  },
}
