<template>
  <div class="snack" :class="active ? 'snack--active' : ''">
    <p class="snack__title">{{ titleValue }}</p>
    <b-opt :title="$t('general.ok')" @click="closeSnackbar" />
  </div>
</template>

<script>
import OptionalButton from './button/optionalButton'

export default {
  components: {
    'b-opt': OptionalButton,
  },
  data() {
    return {
      /**
       * Whether snackbar is active
       */
      active: false,

      /**
       * Auto-close snackbar timeout ID
       */
      autoCloseTimeoutID: '',

      /**
       * Title display value
       */
      titleValue: '',
    }
  },
  methods: {
    /**
     * Handle closing snackbar
     */
    closeSnackbar() {
      // Hide snackbar
      this.active = false

      // Emit snackbar close event
      this.$emit('close')
    },

    /**
     * Handle showing snackbar
     */
    showSnackbar() {
      // Clear existing auto-close timeout
      if (this.autoCloseTimeoutID) {
        clearTimeout(this.autoCloseTimeoutID)
      }

      // Set title display value
      this.titleValue = this.title

      // Show snackbar
      this.active = true

      // Set timeout to auto-close snackbar
      // after 4s
      this.autoCloseTimeoutID = setTimeout(this.closeSnackbar, 4000)

      // Emit snackbar show event
      this.$emit('show')
    },
  },
  props: {
    /**
     * Snackbar title
     */
    title: {
      default: '',
      type: String,
    },
  },
  watch: {
    /**
     * Show snackbar on
     * title update w/ non-empty value
     */
    title(newVal) {
      if (newVal) {
        this.showSnackbar()
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.snack {
  @apply flex items-end z-10;
  @apply absolute bottom-0 mb-4;
  @apply py-2 pl-4 rounded-md;
  @apply text-white bg-black shadow-xs;
  left: 1rem;
  max-width: calc(100% - 2rem);
  transition: opacity 0.3s ease, transform 0.3s ease;

  & .snack__title {
    @apply flex-grow flex;
    @apply items-center leading-tight;
    min-height: 26px;
  }
  & .b.b--opt {
    @apply flex-shrink-0 px-4 font-medium;
    height: 26px;
  }
}

/* Hidden snackbar */
.snack:not(.snack--active) {
  @apply pointer-events-none;
  transform: translateY(100%);
  opacity: 0;
}

@screen desktop {
  .snack {
    @apply max-w-form mb-7 pl-5;
    left: 268px;

    & .snack__title {
      @apply text-base;
      min-height: 32px;
    }
    & .b.b--opt {
      @apply px-5;
      height: 32px;
    }
    & .b.b--opt >>> .b__title {
      @apply text-base;
    }
  }

  /* Hidden snackbar */
  .snack:not(.snack--active) {
    transform: translateX(-100%);
  }
}
</style>
