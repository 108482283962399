<template>
  <div class="app" :class="[layoutClasses, localeClass]">
    <!-- Sidebar -->
    <sidebar class="app__side" />
    <div class="app__main">
      <!--  Ods header -->
      <ods-header v-if="showODS" />
      <!-- Notification bar -->
      <notification-bar
        v-if="showNotificationBar"
        :button-href="notification.buttonHref"
        :button-link-path="notification.buttonLinkPath"
        :button-title="$t(notification.buttonTitle)"
        :message="$t(notification.message)"
        :overlay-href="notification.overlayHref"
        :overlay-link-path="notification.overlayLinkPath"
        :info-type="notification.infoType"
        @confirm="notificationButtonAction"
      />
      <!-- Header -->
      <main-header class="app__hdr" />
      <!-- Page content -->
      <nuxt class="app__page page" />
      <snack :title="snackbarTitle" @close="resetSnackbarTitle" />
    </div>
    <!-- Loading overlay -->
    <overlay class="app__overlay" top :show="isOverlayActive">
      <loading v-show="overlay === 'loading'" />
    </overlay>
    <select-language-popup />
  </div>
</template>

<script>
import DeferInitMixin from '../mixins/layout/deferInit'
import { getFeatureFlag } from '../libs/featureFlag'
import LoadingOverlayMixin from '../mixins/overlay/loading'
import MainHeader from '../components/header/main/mainHeader'
import NotificationMixin from '../mixins/notification'
import OdsHeader from '../components/header/odsHeader'
import SelectLanguagePopup from '../components/selectLanguagePopup'
import Sidebar from '../components/sidebar/sidebar'
import Snackbar from '../components/snackbar'

export default {
  components: {
    'main-header': MainHeader,
    'ods-header': OdsHeader,
    'select-language-popup': SelectLanguagePopup,
    sidebar: Sidebar,
    snack: Snackbar,
  },
  computed: {
    /**
     * Layout component classes
     */
    layoutClasses() {
      let classes = this.showMobileSidebar ? 'app--side_mobile' : ''
      if (this.showMobilePostJob) {
        classes += `${classes ? ' ' : ''}app--post_job_mobile`
      }
      return classes
    },
    localeClass() {
      return this.currentLocale.code === 'en' ? 'en' : ''
    },
    /**
     * Whether to show post job button on mobile
     */
    showMobilePostJob() {
      return this.$route.path === '/'
    },

    /**
     * Whether to show sidebar on mobile
     */
    showMobileSidebar() {
      const hideMobileSidebarPrefixes = [
        '/jobs/post/',
        '/jobs/edit/',
        '/jobs/copy/',
        '/jobs/promotion/',
        '/profile/public/',
        '/profile/internal/',
        '/profile/locations/',
        '/account/',
        '/billing/payment-methods/',
      ]
      return !hideMobileSidebarPrefixes.some(
        (prefix) => this.$route.path.indexOf(prefix) === 0,
      )
    },
    snackbarTitle() {
      return this.$store.getters['snackbar/title']
    },
  },
  created() {
    const userDisplayLanguage = this.$store.getters['profile/displayLanguage']
    if (
      userDisplayLanguage !== this.currentLocale.code &&
      userDisplayLanguage !== null
    ) {
      this.setAppLocale(userDisplayLanguage, '')
    }
  },
  data() {
    return {
      showODS:
        (getFeatureFlag('ODS') &&
          this.$store.getters['profile/brApproved'] &&
          !this.$store.getters['profile/companySuspended']) ||
        this.$route.query.enableOds === 'true',
    }
  },
  methods: {
    resetSnackbarTitle() {
      this.$store.commit('snackbar/resetTitle')
    },
  },
  mixins: [NotificationMixin, LoadingOverlayMixin, DeferInitMixin],
  name: 'DefaultLayout',
}
</script>

<style lang="postcss" scoped>
.app {
  @apply flex h-full bg-grey-50 overflow-hidden;
}
.app__side {
  @apply flex-shrink-0 z-20;
}
.app__main {
  @apply flex-grow flex flex-col min-w-0 min-h-0;
}
.nb {
  @apply flex-shrink-0;
  z-index: 11;
}
.app__hdr {
  @apply flex-shrink-0 z-10;
}
.app__page {
  @apply flex-grow flex items-stretch min-h-0;
  @apply overflow-y-auto overflow-x-hidden;
}

@screen mobile {
  .app {
    @apply flex-col-reverse;
  }
  .app__side {
    @apply absolute bottom-0 left-0;
    transition: transform 0.3s ease 0.4s, opacity 0.3s ease 0.4s;
  }
  .app__hdr {
    @apply hidden;
  }

  /* Hide sidebar */
  .app:not(.app--side_mobile) .app__side {
    @apply pointer-events-none;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease 0.4s, opacity 0.3s ease 0.5s;
  }
  .app:not(.app--side_mobile) .app__side >>> .side__post-job {
    @apply pointer-events-none;
  }

  /* Hide post job button */
  .app:not(.app--post_job_mobile) .app__side >>> .side__post-job {
    @apply pointer-events-none;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease 0.4s, opacity 0.3s ease 0.5s;
  }
}

@screen desktop {
  .overlay .load {
    @apply pointer-events-none;
  }
}
</style>
