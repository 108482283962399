/**
 * NuxtJS middleware to redirect user to login page
 * when not logged in yet
 */
export default function ({ redirect, route, store }) {
  // Whether user is logged in,
  // i.e. employer-only token is available
  const isLoggedIn = store.getters['auth/isLoggedIn']

  // Whether target route is login
  const isLoginRoute = route.path && route.path.indexOf('/login/') === 0

  // Whether target route is signup-related
  const isSignupRoute = [
    '/signup/',
    '/signup/email-sent/',
    '/signup/verify/',
    '/password/set/',
    '/signup/signup-mfa/',
  ].includes(route.path)

  // Whether target route is forgot password-related
  const isForgotPasswordRoute = [
    '/password/forgot/',
    '/password/email-sent/',
    '/password/reset/',
  ].includes(route.path)

  // Whether target route is verify account
  const isVerifyAccountRoute = ['/account/verify/'].includes(route.path)

  // Whether target route is static page route
  const isStaticPageRoute = ['/maintenance/'].includes(route.path)

  // Whether target route does not require login
  const isNoAuthRoute = isLoginRoute || isSignupRoute

  // skip auth check on all static pages
  if (isStaticPageRoute) {
    return route
  } else if (!isLoggedIn && !isNoAuthRoute && !isForgotPasswordRoute) {
    // If target path not equal to '/',
    // pass as query for redirection on login success
    let redirectQuery = route.fullPath !== '/' ? { target: route.fullPath } : {}

    if (isVerifyAccountRoute) {
      redirectQuery = {
        ...redirectQuery,
        from_email: route.query.from_email,
      }
    }

    // Redirect to login page if:
    // 1) not logged in yet, and
    // 2.a) target route is not login, and
    // 2.b) target route is not forgot password-related, and
    // 2.c) target route requires login
    redirect('/login/', redirectQuery)
  } else if (isLoggedIn && isNoAuthRoute) {
    // Redirect to home page if:
    // 1) logged in already, and
    // 2.a) target route is login, or
    // 2.b) target route does not require login
    redirect('/')
  }
}
