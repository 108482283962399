import Tracker from './'

/**
 * Google *gtag.js* tracker interface,
 * uses nuxt *google-gtag* library: https://github.com/nuxt-community/google-gtag
 *
 * gtag.js ref: https://developers.google.com/analytics/devguides/collection/gtagjs
 */
export default class GtagTracker extends Tracker {
  /**
   * @param {Function} gtag
   * @param {string} id
   * @param {string} version
   * @param {string} locale
   */
  constructor(gtag, id, version, locale) {
    super(id)

    // Set gtag function
    if (typeof gtag === 'function') {
      this._gtag = gtag
    }

    // Set app version and language
    this._version = version
    this._locale = locale
    this._userId = ''
  }

  /**
   * Custom events are not sent to GA
   * @param {string} eventName
   * @deprecated
   */
  sendCustomEvent(eventName, eventVariables) {}

  /**
   * Send GA pageview event
   * w/ user ID and app version data
   * @param {string} screenName
   * @param {string} pageLocation
   * @param {string} pagePath
   */
  sendPageViewEvent(screenName, pageLocation, pagePath) {
    if (this._gtag) {
      this._gtag('config', this._id, {
        dimension1: this._version,
        dimension2: this._locale,
        page_location: pageLocation,
        page_path: pagePath,
        page_title: screenName,
        user_id: this._userId,
      })
    }
  }

  /**
   * Set app language to tracker context
   * @param {string} locale
   */
  setLanguage(locale) {
    this._locale = locale
  }

  /**
   * Set user ID to tracker context
   * @param {string} userId
   */
  setUserId(userId) {
    this._userId = userId
  }

  /**
   * User properties are not sent to GA
   * @deprecated
   */
  setUserProperties(properties) {}
}
