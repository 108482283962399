<template>
  <div class="overlay" :class="overlayClasses">
    <span class="overlay__bg" @click="click" />
    <div class="overlay__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    /**
     * Overlay component classes
     */
    overlayClasses() {
      let classes = this.show ? 'overlay--active' : ''
      if (this.top) {
        classes += `${classes ? ' ' : ''}overlay--top`
      }
      return classes
    },
  },
  methods: {
    /**
     * overlay click event
     */
    click() {
      this.$emit('click')
    },
  },
  props: {
    /**
     * Whether to show overlay
     */
    show: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to put overlay to top level
     */
    top: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
.overlay {
  @apply fixed inset-0 z-30 overflow-hidden;
  transition: opacity 0.3s ease;

  & .overlay__bg {
    @apply absolute inset-0 z-0;
  }
  & .overlay__content {
    @apply flex flex-col justify-center;
    @apply items-center absolute inset-0 z-1;
  }

  /* Inactive overlay */
  &:not(.overlay--active) {
    @apply opacity-0 pointer-events-none;
  }
}

/* Top level overlay */
.overlay.overlay--top {
  @apply z-50;

  & .overlay__bg {
    @apply bg-pink-medium;
    opacity: 0.8;
  }
}

@screen mobile {
  .overlay .overlay__bg {
    @apply bg-white;
  }
}

@screen desktop {
  .overlay .overlay__bg {
    @apply bg-black-plain;
    opacity: 0.35;
  }
  .overlay .overlay__content {
    @apply py-page;
    @apply pointer-events-none;
  }
  .overlay .overlay__content > * {
    @apply pointer-events-auto;
  }
}
</style>
