import { CREDIT_CARD_FRAGMENT_FULL, INVOICE_FRAGMENT_FULL } from '../fragments'

/**
 * Query for listing payment methods and failed payments
 */
export const GET_PAYMENT_METHODS_AND_FAILED_PAYMENTS_QUERY = `
query GetPaymentMethodsAndFailedPayments (
  $company: ID!
  $platform: BillingPlatform!
  $status: [InvoiceStatus]
) {
  list_payment_method (
    company: $company
    platform: $platform
  ) {
    credit_cards {
      ...creditCardFragmentFull
    }
  }

  list_failed_payment: list_invoice (
    company: $company
    status: $status
  ) {
    result {
      ...invoiceFragmentFull
    }
  }
}

${CREDIT_CARD_FRAGMENT_FULL}
${INVOICE_FRAGMENT_FULL}
`
