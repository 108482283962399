// Get browser user agent if available
const userAgent =
  typeof window !== 'undefined' &&
  typeof window.navigator !== 'undefined' &&
  typeof window.navigator.userAgent !== 'undefined'
    ? window.navigator.userAgent
    : ''

/**
 * Whether browser is Internet Explorer,
 * ref: https://stackoverflow.com/a/21712356
 */
export const isIE =
  userAgent.includes('MSIE ') || userAgent.includes('Trident/')
