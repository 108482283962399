<template>
  <div class="nb" :class="infoType">
    <div class="nb__redLine" />
    <div class="nb__content">
      <svg-announcement v-if="infoType === 'announcement'" class="svg-fill" />
      <svg-info v-else class="svg-fill" />
      <p>{{ message }}</p>
      <round-button
        class="nb__button-action"
        :href="buttonHref"
        :link-path="buttonLinkPath"
        :link-query="notificationActionLinkQuery"
        :title="buttonTitle"
        @click="confirm"
      />
    </div>
    <round-button
      class="nb__button-overlay"
      :href="overlayHref"
      :link-path="overlayLinkPath"
      :link-query="notificationLinkQuery"
      :title="message"
    />
  </div>
</template>

<script>
import DefaultButton from '../../components/button/defaultButton'
import SvgAnnouncement from '../../assets/svg/ic_megaphone.svg?inline'
import SvgInfo from '../../assets/svg/info.svg?inline'

export default {
  components: {
    'round-button': DefaultButton,
    'svg-announcement': SvgAnnouncement,
    'svg-info': SvgInfo,
  },
  computed: {
    /**
     * Notification bar action link query
     */
    notificationActionLinkQuery() {
      return {
        ...this.linkQuery,
        // Event source should always be this bar's button
        event_source: 'notification_bar.action',
      }
    },

    /**
     * Notification bar link query
     */
    notificationLinkQuery() {
      return {
        ...this.linkQuery,
        // Event source should always be this bar
        event_source: 'notification_bar',
      }
    },
  },
  methods: {
    /**
     * click notification bar
     */
    confirm() {
      this.$emit('confirm')
    },
  },
  props: {
    /**
     * Action button external link
     */
    buttonHref: {
      default: '',
      type: String,
    },

    /**
     * Action button link path
     */
    buttonLinkPath: {
      default: '',
      type: String,
    },

    /**
     * Action button link query
     */
    buttonLinkQuery: {
      default: () => ({}),
      type: Object,
    },

    /**
     * Action button title
     */
    buttonTitle: {
      default: '',
      type: String,
    },

    /**
     * Text & svg style type (defined by ourselves)
     */
    infoType: {
      default: 'warn',
      type: String,
    },

    /**
     * message
     */
    message: {
      default: '',
      type: String,
    },

    /**
     * notification bar external link
     */
    overlayHref: {
      default: '',
      type: String,
    },

    /**
     * notification bar redirect path
     */
    overlayLinkPath: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.nb__content {
  @apply max-w-form-app-desktop flex items-center;
  @apply mx-auto;

  & svg {
    @apply flex-shrink-0 mr-2;
    @apply text-red-500;
  }
  & p {
    @apply flex-grow;
  }
  & .b.b--def {
    @apply flex-shrink-0 text-red-500 ml-2 px-4 shadow-none;
  }
  & .b.b--def:before {
    @apply rounded border border-red-500;
  }
  & .b.b--def:after {
    @apply rounded;
  }
}

.nb__redLine {
  @apply h-2 bg-red-500;
}

.nb {
  @apply relative w-full bg-white;
}

.nb__button-overlay.b.b--def {
  @apply absolute inset-0 max-w-full h-auto;
  @apply bg-transparent shadow-none z-1;

  &::v-deep span,
  &:before {
    @apply hidden;
  }
  &:after {
    @apply rounded-none;
  }
}

.nb__button-action {
  @apply relative z-2;
}

/* announcement color */
.nb.announcement {
  @apply bg-indigo-50;
  & .nb__content {
    & svg {
      @apply text-indigo;
    }
    @apply text-indigo-900;
  }
  & .nb__button-action {
    @apply text-indigo-900 bg-indigo-50;
  }
  & .nb__button-action::before {
    @apply border-indigo-900;
  }
}

@screen mobile {
  .nb__content {
    @apply p-3;

    & svg {
      @apply w-b-sm h-b-sm;
    }
    & .b.b--def {
      @apply h-b;
    }
  }
}

@screen desktop {
  .nb__content {
    @apply p-4;

    & svg {
      @apply w-b h-b;
    }
    & .b.b--def {
      height: 36px;
    }
  }

  .nb {
    @apply shadow-xs;
  }
}
</style>
