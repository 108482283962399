import { getLocalStorage } from '../libs/localStorage'
import { LS_TOKEN_KEY } from '../libs/token'

/**
 * Restore employer token from *local storage*,
 * and set to *Vuex* store
 */
const restoreEmployerToken = async (store) => {
  // Try to get token from *local storage*
  const token = getLocalStorage(LS_TOKEN_KEY)

  // If token found, validate it
  // and persist to *Vuex* store and *local storage* if valid
  if (token) {
    const profile = await store.dispatch('auth/setEmployerToken', token)

    if (
      !(profile && Array.isArray(profile.companies) && profile.companies.length)
    ) {
      // If employer token invalid or profile not restored,
      // clear the restored token,
      // redirect user to login page
      store.commit('auth/setTokenAndPayload')
    }
  }
}

/**
 * NuxtJS plugin to restore employer token on client side
 */
export default async function ({ route, store }) {
  // On client side,
  // try to restore employer token
  // and restore user profile
  if (process.client) {
    await restoreEmployerToken(store)
  }
}
