/**
 * Auth *Vuex* module mutations
 */
export default {
  /**
   * Set provided token value to store
   */
  setTokenAndPayload(state, { payload = null, token = '' } = {}) {
    state.token = token
    state.payload = payload
  },
}
