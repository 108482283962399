import Vue from 'vue'

/**
 * Edit job *Vuex* module mutations
 */
export default {
  /**
   * Clear edit job fields
   */
  resetEditJobData(state) {
    state.job = {}
  },

  /**
   * Clear edit company profile fields
   */
  resetEditProfileData(state) {
    state.profile = {}
  },

  /**
   * Reset post job completed steps to *false*
   * based on provided steps count
   * @param {number} stepCount
   */
  resetStepsCompleted(state, stepCount = 0) {
    const stepsCompleted = []
    for (let i = 0; i < stepCount; i++) {
      stepsCompleted.push(false)
    }
    Vue.set(state, 'stepsCompleted', stepsCompleted)
  },

  /**
   * Set post job step to completed
   * based on provided step index
   * @param {number} stepIndex
   */
  setStepCompleted(state, stepIndex) {
    if (stepIndex >= 0 && stepIndex < state.stepsCompleted.length) {
      Vue.set(state.stepsCompleted, stepIndex, true)
    }
  },

  /**
   * Update edit job fields
   * based on provided data
   */
  updateEditJobData(state, job) {
    Object.keys(job).forEach((fieldName) => {
      Vue.set(state.job, fieldName, job[fieldName])
    })
  },

  /**
   * Update edit company profile fields
   * based on provided data
   */
  updateEditProfileData(state, profile) {
    Object.keys(profile).forEach((fieldName) => {
      Vue.set(state.profile, fieldName, profile[fieldName])
    })
  },
}
