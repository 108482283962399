<template>
  <component
    :is="buttonComponent"
    class="b b--opt"
    tabindex="0"
    v-bind="buttonProps"
    @click="onClick"
  >
    <span class="b__title">{{ title }}</span>
  </component>
</template>

<script>
import ButtonMixin from '../../mixins/button'

export default {
  mixins: [ButtonMixin],
}
</script>

<style lang="postcss" scoped>
.b.b--opt {
  @apply px-6;
  @apply whitespace-no-wrap;

  &:not(.b--row) {
    height: 2em;
  }
  &:not(.b--row) .b__title {
    @apply leading-loose;
  }
  &.b--narrow {
    @apply px-2;
  }
}

@screen desktop {
  .b:not(.b--row) .b__title {
    @apply text-sm;
  }
}
</style>
