import { postForm } from '..'

/**
 * Delay before uploaded image
 * is ready on server, in ms
 */
export const fileUploadReadyDelay = 3000

/**
 * Generate form data for
 * uploading file to *S3* bucket
 * @param {Blob} file
 * @returns {FormData}
 */
export function getFileFormData(payload, file) {
  if (typeof FormData !== 'undefined' && file instanceof Blob) {
    // Create empty form data
    const data = new FormData()

    // Append payload key-value pairs to form data
    if (payload) {
      Object.keys(payload).forEach((key) => data.append(key, payload[key]))
    }

    // Append file to end of form data
    data.append('file', file)

    return data
  }

  return null
}

/**
 * Send POST request to *S3* bucket
 * for uploading file
 * @param {string} url
 * @param {File} file
 */
export function postFileToS3(url, payload, file) {
  // Generate form data
  const body = getFileFormData(payload, file)

  // Post form data to S3 bucket
  return postForm(url, body)
}
