import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d5cf562 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _1dcfe41a = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5a2ed0ac = () => interopDefault(import('../pages/applications/index.vue' /* webpackChunkName: "pages/applications/index" */))
const _d206ea54 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _4a7f07bd = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _5ddeb896 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _f749ae3e = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _60e2bb05 = () => interopDefault(import('../pages/suspended.vue' /* webpackChunkName: "pages/suspended" */))
const _34b1cae1 = () => interopDefault(import('../pages/account/verify.vue' /* webpackChunkName: "pages/account/verify" */))
const _59ecffc3 = () => interopDefault(import('../pages/billing/invoices/index.vue' /* webpackChunkName: "pages/billing/invoices/index" */))
const _5e34e678 = () => interopDefault(import('../pages/billing/payment-methods/index.vue' /* webpackChunkName: "pages/billing/payment-methods/index" */))
const _667f8538 = () => interopDefault(import('../pages/jobs/copy.vue' /* webpackChunkName: "pages/jobs/copy" */))
const _077f3d58 = () => interopDefault(import('../pages/jobs/detail.vue' /* webpackChunkName: "pages/jobs/detail" */))
const _cfc45ef0 = () => interopDefault(import('../pages/login/login-mfa.vue' /* webpackChunkName: "pages/login/login-mfa" */))
const _29e46672 = () => interopDefault(import('../pages/password/email-sent.vue' /* webpackChunkName: "pages/password/email-sent" */))
const _fdb9627e = () => interopDefault(import('../pages/password/forgot.vue' /* webpackChunkName: "pages/password/forgot" */))
const _c34db5be = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _5cfae934 = () => interopDefault(import('../pages/password/set.vue' /* webpackChunkName: "pages/password/set" */))
const _f4a6b630 = () => interopDefault(import('../pages/profile/create.vue' /* webpackChunkName: "pages/profile/create" */))
const _a88505ae = () => interopDefault(import('../pages/profile/internal.vue' /* webpackChunkName: "pages/profile/internal" */))
const _10b269bc = () => interopDefault(import('../pages/profile/locations.vue' /* webpackChunkName: "pages/profile/locations" */))
const _b4d79056 = () => interopDefault(import('../pages/profile/public.vue' /* webpackChunkName: "pages/profile/public" */))
const _39a48060 = () => interopDefault(import('../pages/profile/upload-br.vue' /* webpackChunkName: "pages/profile/upload-br" */))
const _3360963b = () => interopDefault(import('../pages/profile/upload-logo.vue' /* webpackChunkName: "pages/profile/upload-logo" */))
const _0cebe92a = () => interopDefault(import('../pages/signup/email-sent.vue' /* webpackChunkName: "pages/signup/email-sent" */))
const _590d4cb4 = () => interopDefault(import('../pages/signup/signup-mfa.vue' /* webpackChunkName: "pages/signup/signup-mfa" */))
const _c0e1c20c = () => interopDefault(import('../pages/signup/verify.vue' /* webpackChunkName: "pages/signup/verify" */))
const _7380567a = () => interopDefault(import('../pages/billing/payment-methods/create.vue' /* webpackChunkName: "pages/billing/payment-methods/create" */))
const _29334889 = () => interopDefault(import('../pages/jobs/edit/attributes.vue' /* webpackChunkName: "pages/jobs/edit/attributes" */))
const _6d624644 = () => interopDefault(import('../pages/jobs/edit/contact.vue' /* webpackChunkName: "pages/jobs/edit/contact" */))
const _8dbfc840 = () => interopDefault(import('../pages/jobs/edit/info.vue' /* webpackChunkName: "pages/jobs/edit/info" */))
const _61274f9c = () => interopDefault(import('../pages/jobs/edit/locations.vue' /* webpackChunkName: "pages/jobs/edit/locations" */))
const _1e732e7c = () => interopDefault(import('../pages/jobs/edit/requirements.vue' /* webpackChunkName: "pages/jobs/edit/requirements" */))
const _557d12c6 = () => interopDefault(import('../pages/jobs/edit/salary-benefits.vue' /* webpackChunkName: "pages/jobs/edit/salary-benefits" */))
const _54fe393a = () => interopDefault(import('../pages/jobs/edit/shifts.vue' /* webpackChunkName: "pages/jobs/edit/shifts" */))
const _cf26419a = () => interopDefault(import('../pages/jobs/post/attributes.vue' /* webpackChunkName: "pages/jobs/post/attributes" */))
const _29aad674 = () => interopDefault(import('../pages/jobs/post/contact.vue' /* webpackChunkName: "pages/jobs/post/contact" */))
const _1e527a0a = () => interopDefault(import('../pages/jobs/post/info.vue' /* webpackChunkName: "pages/jobs/post/info" */))
const _1a6b3bb2 = () => interopDefault(import('../pages/jobs/post/locations.vue' /* webpackChunkName: "pages/jobs/post/locations" */))
const _77f4fcec = () => interopDefault(import('../pages/jobs/post/requirements.vue' /* webpackChunkName: "pages/jobs/post/requirements" */))
const _1aad7a33 = () => interopDefault(import('../pages/jobs/post/salary-benefits.vue' /* webpackChunkName: "pages/jobs/post/salary-benefits" */))
const _90d741e6 = () => interopDefault(import('../pages/jobs/post/shifts.vue' /* webpackChunkName: "pages/jobs/post/shifts" */))
const _1b092acc = () => interopDefault(import('../pages/jobs/promotion/create-payment-method.vue' /* webpackChunkName: "pages/jobs/promotion/create-payment-method" */))
const _7cbe58aa = () => interopDefault(import('../pages/jobs/promotion/create.vue' /* webpackChunkName: "pages/jobs/promotion/create" */))
const _5369f9de = () => interopDefault(import('../pages/jobs/promotion/performance.vue' /* webpackChunkName: "pages/jobs/promotion/performance" */))
const _4daadb7c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404/",
    component: _0d5cf562,
    pathToRegexpOptions: {"strict":true},
    name: "404"
  }, {
    path: "/account/",
    component: _1dcfe41a,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/applications/",
    component: _5a2ed0ac,
    pathToRegexpOptions: {"strict":true},
    name: "applications"
  }, {
    path: "/login/",
    component: _d206ea54,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/maintenance/",
    component: _4a7f07bd,
    pathToRegexpOptions: {"strict":true},
    name: "maintenance"
  }, {
    path: "/profile/",
    component: _5ddeb896,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/signup/",
    component: _f749ae3e,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/suspended/",
    component: _60e2bb05,
    pathToRegexpOptions: {"strict":true},
    name: "suspended"
  }, {
    path: "/account/verify/",
    component: _34b1cae1,
    pathToRegexpOptions: {"strict":true},
    name: "account-verify"
  }, {
    path: "/billing/invoices/",
    component: _59ecffc3,
    pathToRegexpOptions: {"strict":true},
    name: "billing-invoices"
  }, {
    path: "/billing/payment-methods/",
    component: _5e34e678,
    pathToRegexpOptions: {"strict":true},
    name: "billing-payment-methods"
  }, {
    path: "/jobs/copy/",
    component: _667f8538,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-copy"
  }, {
    path: "/jobs/detail/",
    component: _077f3d58,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-detail"
  }, {
    path: "/login/login-mfa/",
    component: _cfc45ef0,
    pathToRegexpOptions: {"strict":true},
    name: "login-login-mfa"
  }, {
    path: "/password/email-sent/",
    component: _29e46672,
    pathToRegexpOptions: {"strict":true},
    name: "password-email-sent"
  }, {
    path: "/password/forgot/",
    component: _fdb9627e,
    pathToRegexpOptions: {"strict":true},
    name: "password-forgot"
  }, {
    path: "/password/reset/",
    component: _c34db5be,
    pathToRegexpOptions: {"strict":true},
    name: "password-reset"
  }, {
    path: "/password/set/",
    component: _5cfae934,
    pathToRegexpOptions: {"strict":true},
    name: "password-set"
  }, {
    path: "/profile/create/",
    component: _f4a6b630,
    pathToRegexpOptions: {"strict":true},
    name: "profile-create"
  }, {
    path: "/profile/internal/",
    component: _a88505ae,
    pathToRegexpOptions: {"strict":true},
    name: "profile-internal"
  }, {
    path: "/profile/locations/",
    component: _10b269bc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-locations"
  }, {
    path: "/profile/public/",
    component: _b4d79056,
    pathToRegexpOptions: {"strict":true},
    name: "profile-public"
  }, {
    path: "/profile/upload-br/",
    component: _39a48060,
    pathToRegexpOptions: {"strict":true},
    name: "profile-upload-br"
  }, {
    path: "/profile/upload-logo/",
    component: _3360963b,
    pathToRegexpOptions: {"strict":true},
    name: "profile-upload-logo"
  }, {
    path: "/signup/email-sent/",
    component: _0cebe92a,
    pathToRegexpOptions: {"strict":true},
    name: "signup-email-sent"
  }, {
    path: "/signup/signup-mfa/",
    component: _590d4cb4,
    pathToRegexpOptions: {"strict":true},
    name: "signup-signup-mfa"
  }, {
    path: "/signup/verify/",
    component: _c0e1c20c,
    pathToRegexpOptions: {"strict":true},
    name: "signup-verify"
  }, {
    path: "/billing/payment-methods/create/",
    component: _7380567a,
    pathToRegexpOptions: {"strict":true},
    name: "billing-payment-methods-create"
  }, {
    path: "/jobs/edit/attributes/",
    component: _29334889,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-edit-attributes"
  }, {
    path: "/jobs/edit/contact/",
    component: _6d624644,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-edit-contact"
  }, {
    path: "/jobs/edit/info/",
    component: _8dbfc840,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-edit-info"
  }, {
    path: "/jobs/edit/locations/",
    component: _61274f9c,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-edit-locations"
  }, {
    path: "/jobs/edit/requirements/",
    component: _1e732e7c,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-edit-requirements"
  }, {
    path: "/jobs/edit/salary-benefits/",
    component: _557d12c6,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-edit-salary-benefits"
  }, {
    path: "/jobs/edit/shifts/",
    component: _54fe393a,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-edit-shifts"
  }, {
    path: "/jobs/post/attributes/",
    component: _cf26419a,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-post-attributes"
  }, {
    path: "/jobs/post/contact/",
    component: _29aad674,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-post-contact"
  }, {
    path: "/jobs/post/info/",
    component: _1e527a0a,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-post-info"
  }, {
    path: "/jobs/post/locations/",
    component: _1a6b3bb2,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-post-locations"
  }, {
    path: "/jobs/post/requirements/",
    component: _77f4fcec,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-post-requirements"
  }, {
    path: "/jobs/post/salary-benefits/",
    component: _1aad7a33,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-post-salary-benefits"
  }, {
    path: "/jobs/post/shifts/",
    component: _90d741e6,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-post-shifts"
  }, {
    path: "/jobs/promotion/create-payment-method/",
    component: _1b092acc,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-promotion-create-payment-method"
  }, {
    path: "/jobs/promotion/create/",
    component: _7cbe58aa,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-promotion-create"
  }, {
    path: "/jobs/promotion/performance/",
    component: _5369f9de,
    pathToRegexpOptions: {"strict":true},
    name: "jobs-promotion-performance"
  }, {
    path: "/",
    component: _4daadb7c,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
