export default function ({app: {router}}, inject) {
  if(false){
    // inject empty gtag function for disabled mode
    inject('gtag', () => {})
    return
  }

  window.dataLayer = window.dataLayer || []
  function gtag () {
    dataLayer.push(arguments)
    if(false){
      console.debug('gtag tracking called with following arguments:', arguments)
    }
  }
  inject('gtag', gtag)
  gtag('js', new Date())
  gtag('config','G-REQGP5L840',{
  "send_page_view": false,
  "transport_type": "beacon"
})

  if(!true){
    router.afterEach((to) => {
      gtag('config', 'G-REQGP5L840', {'page_path': to.fullPath})
    })
  }

  // additional accounts

    gtag('config', 'AW-833748114',{
  "allow_ad_personalization_signals": false,
  "send_page_view": false,
  "transport_type": "beacon"
})
}
