import { render, staticRenderFns } from "./notFound.vue?vue&type=template&id=0ac25552&scoped=true&"
import script from "./notFound.vue?vue&type=script&lang=js&"
export * from "./notFound.vue?vue&type=script&lang=js&"
import style0 from "./notFound.vue?vue&type=style&index=0&id=0ac25552&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ac25552",
  null
  
)

export default component.exports