
export const vueI18n = {"fallbackLocale":"zh-hant"}

export const vueI18nLoader = false

export const locales = [{"code":"en","codeAlt":"en","file":"en.js","iso":"en","name":"English"},{"code":"zh-hant","codeAlt":"zh_hk","file":"zh-hant.js","iso":"zh-Hant","name":"繁體中文"}]

export const defaultLocale = 'zh-hant'

export const routesNameSeparator = '___'

export const defaultLocaleRouteNameSuffix = 'default'

export const strategy = 'no_prefix'

export const lazy = true

export const langDir = './locales/'

export const rootRedirect = null

export const detectBrowserLanguage = {"useCookie":true,"cookieKey":"moovup_ep_locale","alwaysRedirect":true,"fallbackLocale":null}

export const differentDomains = false

export const forwardedHost = false

export const seo = false

export const baseUrl = 'https://employer.moovup.com'

export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}

export const parsePages = true

export const pages = {}

export const encodePaths = true

export const beforeLanguageSwitch = () => null

export const onLanguageSwitched = () => null

export const MODULE_NAME = 'nuxt-i18n'

export const LOCALE_CODE_KEY = 'code'

export const LOCALE_ISO_KEY = 'iso'

export const LOCALE_DOMAIN_KEY = 'domain'

export const LOCALE_FILE_KEY = 'file'

export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}

export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'

export const localeCodes = ["en","zh-hant"]
