<template>
  <b-icon-text
    class="b--dropdown"
    :class="plain ? 'b--plain' : ''"
    :title="title"
    @click="onClick"
  >
    <div slot="icon" class="b__icon">
      <svg-arrow-down v-show="!subCount" class="svg-fill" />
      <!-- Selected children count -->
      <span v-show="subCount" class="b__count">{{ subCount }}</span>
    </div>
  </b-icon-text>
</template>

<script>
import IconTextButton from './iconTextButton'
import SvgArrowDown from '../../assets/svg/arrowDown.svg?inline'

export default {
  components: {
    'b-icon-text': IconTextButton,
    'svg-arrow-down': SvgArrowDown,
  },
  methods: {
    /**
     * Emit click event to parent
     */
    onClick() {
      this.$emit('click')
    },
  },
  props: {
    /**
     * Whether to use plain layout
     */
    plain: {
      default: false,
      type: Boolean,
    },

    /**
     * Number of children selected
     */
    subCount: {
      default: 0,
      type: Number,
    },

    /**
     * Button title
     */
    title: {
      required: true,
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.b.b--dropdown {
  @apply flex-row-reverse justify-start;
  @apply px-2 bg-white;

  & .b__icon {
    @apply pointer-events-none;
  }
  & svg {
    @apply -mx-1;
    transition: transform 0.2s ease;
  }
  & >>> .b__title {
    @apply flex-grow mr-2 text-sm text-left;
  }
}

/* Selected children count */
.b__count {
  @apply flex justify-center items-center;
  @apply leading-none text-white bg-pink;
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  font-size: 10px;
}

/* Default layout */
.b.b--dropdown:not(.b--plain) {
  &:before {
    @apply absolute inset-0 rounded-md;
    @apply border border-solid border-pink-medium;
    @apply z-1 pointer-events-none;
    content: '';
  }
}

@screen desktop {
  .b.b--dropdown {
    @apply px-3;
  }
}
</style>
