<template>
  <div class="app">
    <nuxt />
  </div>
</template>

<script>
export default {
  name: 'SimpleLayout',
}
</script>

<style scoped></style>
