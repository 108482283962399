import { getPostJobSteps, getStepIndexByPath } from '../libs/multiStep'

/**
 * NuxtJS middleware to redirect user to
 * next incomplete post job step
 */
export default function ({ redirect, route, store }) {
  // Get post job step paths
  // based on employment type name
  const steps = getPostJobSteps(route)

  // If post job steps available
  if (steps.length) {
    // Initialize post job completed steps list
    // if not ready yet
    if (store.state.editJob.stepsCompleted.length !== steps.length) {
      store.commit('editJob/resetStepsCompleted', steps.length)
    }

    // Get current post job step index
    const stepCurrent = getStepIndexByPath(steps, route.path)
    // Get next incomplete post job step index
    const stepNextIncomplete = store.getters['editJob/stepNextIncomplete']

    // Redirect to next incomplete step
    // if available and before current step
    if (steps[stepNextIncomplete] && stepNextIncomplete < stepCurrent) {
      redirect({
        path: steps[stepNextIncomplete].path,
        query: steps[stepNextIncomplete].query,
      })
    }
  } else {
    // Otherwise (wrong employment type name)
    // redirect to home page
    redirect('/')
  }
}
