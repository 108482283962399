import GtagTracker from './gtag'

/**
 * Google *gtag.js* tracker interface,
 * for use in sending Google Ads conversions
 * uses nuxt *google-gtag* library: https://github.com/nuxt-community/google-gtag
 */
export default class GoogleAdsTracker extends GtagTracker {
  /**
   * Send conversion
   * @param {string} conversionId
   */
  sendConversion(conversionId) {
    this._gtag('event', 'conversion', {
      send_to: `${this._id}/${conversionId}`,
    })
  }

  /**
   * Pageviews are not sent to Google Ads
   * @deprecated
   */
  sendPageViewEvent(screenName, pageLocation, pagePath) {}
}
