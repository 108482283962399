<template>
  <main class="not-found-page">
    <not-found />
  </main>
</template>

<script>
import NotFound from '../components/notFound'

export default {
  components: {
    'not-found': NotFound,
  },
  layout: 'popup',
  name: 'NotFoundPage',
}
</script>

<style lang="postcss" scoped>
@screen mobile {
  .not-found-page {
    @apply h-full;
  }
}

@screen desktop {
  .not-found-page {
    & .not-found {
      @apply pt-0;
    }
  }
}
</style>
