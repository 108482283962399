export const LinkKeys = {
  biddingFaq: 'bidding-faq',
  employer: 'employer',
  faq: 'faq',
  privacy: 'privacy',
  seeker: 'seeker',
  temp: 'temp',
  tnc: 'tnc',
}

const linksMap = {
  en: {
    [LinkKeys.biddingFaq]:
      'https://moovup.com/hk/en/employer/job-ad-booster-faq/',
    [LinkKeys.employer]:
      'https://moovup.com/hk/en/employer/recruitment-services/',
    [LinkKeys.faq]: 'https://moovup.com/hk/en/employer/faq/',
    [LinkKeys.privacy]: 'https://moovup.com/hk/en/privacy-policy/',
    [LinkKeys.seeker]: 'https://moovup.com/hk/en/',
    [LinkKeys.temp]:
      'https://moovup.com/hk/en/employer/temporary-worker-matching-services/',
    [LinkKeys.tnc]: 'https://moovup.com/hk/en/terms-and-conditions/',
  },
  'zh-hant': {
    [LinkKeys.biddingFaq]:
      'https://moovup.com/hk/employer/提升曝光功能-常見問題/',
    [LinkKeys.employer]: 'https://moovup.com/hk/employer/recruitment-services/',
    [LinkKeys.faq]: 'https://moovup.com/hk/employer/常見問題/',
    [LinkKeys.privacy]: 'https://moovup.com/hk/privacy-policy/',
    [LinkKeys.seeker]: 'https://moovup.com/hk/',
    [LinkKeys.temp]: 'https://moovup.com/hk/employer/臨時工配對服務/',
    [LinkKeys.tnc]: 'https://moovup.com/hk/terms-and-conditions/',
  },
}

export const getLinkByKey = (locale, linkKey) => {
  // fallback to hk locale
  const map = linksMap[locale] || linksMap['zh-hant']
  return map[linkKey]
}
