import { PROFILE_FRAGMENT } from '../fragments'

/**
 * Query for getting user profile
 */
export const GET_PROFILE_QUERY = `
query GetProfile {
  user_profile {
    ...profileFragment
  }
  employer_setting {
    display_language
  }
}

${PROFILE_FRAGMENT}
`
