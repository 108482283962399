/**
 * Add trailing slash to provided path
 * @param {string} path
 */
export function addTrailingSlash(path) {
  if (path) {
    // Add trailing slash to provided path,
    // then replace consecutive trailing slashes with one
    return `${path}/`.replace(/\/+$/g, '/')
  }
  return ''
}
