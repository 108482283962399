import EventTracker from '../libs/tracking/eventTracker'
import FbAnalyticsTracker from '../libs/tracking/platform/fbAnalytics'
import FbPixelTracker from '../libs/tracking/platform/fbPixel'
import FirebaseTracker from '../libs/tracking/platform/firebase'
import { getScreenName } from '../libs/tracking/screenName'
import GoogleAdsTracker from '../libs/tracking/platform/googleAds'
import GtagTracker from '../libs/tracking/platform/gtag'
import { trackingPlatforms } from '../libs/tracking/platform'

/**
 * Initialize event tracker libraries
 * @param {Function} inject
 */
const initEventTracker = (app, inject) => {
  // Get app version and language
  const version = process.env.EMPLOYER_WEB__VERSION
  const locale = app.i18n.locale || ''

  // Create tracker instances for different platofrms
  const fbAnalyticsTracker = new FbAnalyticsTracker(
    process.env.EMPLOYER_WEB__FACEBOOK_APP_ID,
    version,
  )
  const fbPixelTracker = new FbPixelTracker(
    process.env.EMPLOYER_WEB__FACEBOOK_PIXEL_ID,
  )
  const firebaseTracker = new FirebaseTracker(
    app.$fireAnalytics,
    version,
    locale,
  )
  const gaAdsTracker = new GoogleAdsTracker(
    app.$gtag,
    process.env.EMPLOYER_WEB__GA_ADS_ID,
    version,
    locale,
  )
  const gaWebTracker = new GtagTracker(
    app.$gtag,
    process.env.EMPLOYER_WEB__GA_WEB_ID,
    version,
    locale,
  )

  // Create new event tracker instance
  const eventTracker = new EventTracker({
    [trackingPlatforms.fbAnalytics]: fbAnalyticsTracker,
    [trackingPlatforms.fbPixel]: fbPixelTracker,
    [trackingPlatforms.firebase]: firebaseTracker,
    [trackingPlatforms.gaAds]: gaAdsTracker,
    [trackingPlatforms.gaWeb]: gaWebTracker,
  })

  /**
   * Inject event tracker instance into vue and nuxt contexts,
   * can be used as *this.$eventTracker* in vue components,
   * and *app.$eventTracker* in nuxt plugins
   */
  inject('eventTracker', eventTracker)
}

/**
 * Initialize sending pageview tracking
 * after route change
 */
const initPageViewTracking = (app, store) => {
  app.router.afterEach((route) => {
    // Extract screen name from route
    const screenName = getScreenName(route)

    // Generate page URL based on
    // base URL and route full path
    const pageURL = `${process.env.EMPLOYER_WEB__BASE_URL}${route.fullPath}`

    // Set user ID flag and pageview event to all trackers
    app.$eventTracker.setUserId(store.getters['auth/userId'])
    app.$eventTracker.sendPageViewEvent(screenName, pageURL, route.fullPath)
  })
}

/**
 * NuxtJS plugin to setup event tracking
 */
export default function ({ app, store }, inject) {
  // Initialize event trackers
  initEventTracker(app, inject)

  // Initialize pageview tracking
  initPageViewTracking(app, store)
}
