import { injectScript } from '../utils'
import Tracker from './'

/**
 * Initialize Pixel API
 */
const getFbq = () => {
  const fbq = function () {
    if (fbq.callMethod) {
      fbq.callMethod.apply(fbq, arguments)
    } else {
      fbq.queue.push(arguments)
    }
  }
  fbq.queue = []
  fbq.push = fbq
  fbq.loaded = true
  fbq.version = '2.0'

  return fbq
}

/**
 * Facebook Pixel tracker,
 * uses *Facebook Pixel* for sending tracking events,
 * ref: https://developers.facebook.com/docs/facebook-pixel/
 */
export default class FbPixelTracker extends Tracker {
  constructor(id) {
    super(id)
    this._id = id
    this._fbInit = false

    // Initialize Pixel API
    const fbq = getFbq()
    this._fbq = fbq

    // Set Pixel API reference to window
    if (typeof window !== 'undefined' && !window.fbq) {
      window.fbq = fbq
      window._fbq = fbq
    }
  }

  /**
   * Handle deferred initialisation
   */
  deferInit() {
    // Only initialize once
    if (!this._deferInitDone) {
      injectScript('https://connect.facebook.net/en_US/fbevents.js', true)
      super.deferInit()
    }
  }

  /**
   * Send conversion
   * @param {string} conversionId
   */
  sendConversion(conversionId) {
    // Set Pixel ID once
    // before sending conversion
    if (!this._fbInit) {
      this._fbq('init', this._id)
      this._fbInit = true
    }

    this._fbq('track', 'PageView')
  }
}
