<template>
  <span class="masthead">
    <svg-moovup-logo class="masthead__logo" />
  </span>
</template>

<script>
import SvgMoovupLogo from '../assets/svg/moovupLogo_v4_red.svg?inline'

export default {
  components: {
    'svg-moovup-logo': SvgMoovupLogo,
  },
}
</script>

<style lang="postcss" scoped>
.masthead {
  @apply flex items-center w-full justify-center pt-1;
}
.masthead__logo {
  width: 72%;
}

@screen mobile {
  .masthead__logo {
    width: 60%;
  }
}
</style>
