<template>
  <component
    :is="buttonComponent"
    class="b b--main"
    tabindex="0"
    v-bind="buttonProps"
    @click="onClick"
  >
    <span class="b__title">{{ title }}</span>
  </component>
</template>

<script>
import ButtonMixin from '../../mixins/button'

export default {
  mixins: [ButtonMixin],
}
</script>
