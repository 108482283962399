import { APPLICATION_FRAGMENT_FULL, JOB_FRAGMENT_CORE } from '../fragments'

/**
 * Query for listing applications to a company
 */
export const GET_APPLICATIONS_PAGE_BASE_QUERY = `
query GetApplicationsPage (
  $application: [ID]
  $company: ID!
  $district: [ID]
  $is_rejected: Boolean
  $is_reviewed: Boolean
  $is_saved: Boolean
  $job: [ID]
  $limit: Int
  $offset: Int
  $seeker: [ID]
  $state: [ReadJobState]
  $jobsLimit: Int
  $jobsState: [ReadJobState]
) {
  list_application (
    application: $application
    company: $company
    district: $district
    is_rejected: $is_rejected
    is_reviewed: $is_reviewed
    is_saved: $is_saved
    job: $job
    job_state: $state
    limit: $limit
    offset: $offset
    seeker: $seeker
  ) {
    result {
      ...applicationFragmentFull
    }
    total
  }

  __SLOT__
}

${APPLICATION_FRAGMENT_FULL}
__FRGAMENT__
`

/**
 * Query for load more applications
 */
export const GET_APPLICATIONS_QUERY = GET_APPLICATIONS_PAGE_BASE_QUERY.replace(
  '__SLOT__',
  '',
).replace('__FRGAMENT__', '')

/**
 * Query for load application page
 */
export const GET_APPLICATIONS_PAGE_QUERY =
  GET_APPLICATIONS_PAGE_BASE_QUERY.replace(
    '__SLOT__',
    `
  list_job (
    company: $company
    limit: $jobsLimit
    state: $jobsState
  ) {
    result {
      ...jobFragmentCore
    }
  }
  `,
  ).replace('__FRGAMENT__', `${JOB_FRAGMENT_CORE}`)

export const GET_TOTAL_APPLICATIONS_QUERY = `
query CountApplications (
  $company: ID!
  $is_reviewed: Boolean
) {
  list_application (
    company: $company
    is_reviewed: $is_reviewed
  ) {
    total
  }
}
`
