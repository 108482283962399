<template>
  <m-drop-down class="hdr-main-support" :items="items" @click="onItemClick">
    <b-drop-down slot="button" plain :title="$t('header.supportTitle')" />
  </m-drop-down>
</template>

<script>
import DropDownButton from '../../button/dropDownButton'
import DropDownMenu from '../../menu/dropDownMenu'
import MenuMixin from '../../../mixins/menu'

export default {
  components: {
    'b-drop-down': DropDownButton,
    'm-drop-down': DropDownMenu,
  },
  computed: {
    /**
     * Header menu items
     */
    companyId() {
      return this.$store.getters['profile/companyID']
    },
    items() {
      return this.supportItems
    },
  },
  mixins: [MenuMixin],
}
</script>

<style lang="postcss" scoped>
.m.m--dropdown::v-deep {
  & .m__list {
    @apply left-auto right-0;
    width: 200px;
  }
  &:hover .b.b--dropdown svg {
    transform: rotate(180deg);
  }
}
.b.b--dropdown::v-deep {
  @apply bg-grey-50;

  & .b__title {
    @apply text-base;
  }
}
</style>
