import _padStart from 'lodash/padStart'
import addMonths from 'date-fns/addMonths'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import format from 'date-fns/format'
import getDaysInMonth from 'date-fns/getDaysInMonth'
import usLocale from 'date-fns/locale/en-US'

/**
 * Regexp pattern for *HH:mm* time string,
 * ref: https://stackoverflow.com/a/7536768
 */
export const timeStrRegexp = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/

/**
 * Format ISO 8601 time string into date string
 * @param {string} date
 */
export function formatDateStr(date) {
  if (date) {
    return formatLocaleDate(new Date(date), 'yyyy/MM/dd')
  }
  return ''
}

/**
 * Format ISO 8601 time string into timestamp string
 * @param {string} date
 */
export function formatDateTimeStr(date) {
  if (date) {
    return formatLocaleDate(new Date(date), 'yyyy/MM/dd HH:mm')
  }
  return ''
}

/**
 * Format timestamp into *HH:mm* string
 * @param {number} time Number of minutes from midnight
 */
export function formatHHmm(time) {
  if (time >= 0) {
    // Get hour in 24-hr format
    const hours = parseInt((time / 60) % 24)
    // Get minute offset
    const minutes = time % 60

    // Return HH:mm time string with padded leading zeroes
    return `${padTimeZero(hours)}:${padTimeZero(minutes)}`
  }
  return ''
}

/**
 * Format *Date* object into provided format,
 * with support on timezones and locales
 * @param {Date} date
 */
export function formatLocaleDate(
  date,
  dateFormat = "yyyy-MM-dd'T'HH:mm:ssXXX",
) {
  if (isDateValid(date)) {
    return format(date, dateFormat, {
      // TODO: support other locales
      locale: usLocale,
    })
  }
  return ''
}

/**
 * Format *Date* object into UTC time string
 * @param {Date} date
 */
export function formatUTCDate(date, dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'") {
  if (isDateValid(date)) {
    // Convert date into UTC value
    // by adding timezone offset
    const dateUTC = new Date(date.getTime() + date.getTimezoneOffset() * 60000)

    // Format offseted date into string
    // with hardcoded timezone *Z*
    return formatLocaleDate(dateUTC, dateFormat)
  }
  return ''
}

/**
 * Format ISO 8601 time string into *yyyy/MM* string
 * @param {string} date
 */
export function formatYearMonthStr(date) {
  if (date) {
    return formatLocaleDate(new Date(date), 'yyyy/MM')
  }
  return ''
}

/**
 * Format ISO 8601 time string into *MM/yyyy* string
 * @param {string} date
 */
export function formatMonthYearStr(date) {
  if (date) {
    return formatLocaleDate(new Date(date), 'MM/yyyy')
  }
  return ''
}

/**
 * Ensure timestamp is down to milliseconds
 * @param {number} timestamp
 */
export function getMSecTimestamp(timestamp) {
  return timestamp < 10000000000 ? timestamp * 1000 : timestamp
}

/**
 * Get timestamp from *Date* object
 * @param {Date} date
 */
export function getDateTimestamp(date) {
  if (isDateValid(date)) {
    return date.getTime()
  }
  return 0
}

/**
 * Get difference between target date and now
 * @param {Date} date
 */
export function getDifferenceFromNow(date) {
  return differenceInCalendarDays(new Date(date), new Date())
}

/**
 * Get last day in the month of provided *Date*
 * @param {Date} date
 */
export function getMonthLastDay(date) {
  if (isDateValid(date)) {
    return getDaysInMonth(date)
  }
  return 0
}

/**
 * Get list of months from provided date (inclusive)
 * @param {Date} baseDate
 * @returns {[Date]}
 */
export function getMonthsFromDate(baseDate) {
  const months = []
  if (isDateValid(baseDate)) {
    let date = baseDate
    const currentDate = new Date()

    // For months up to current month
    while (date <= currentDate) {
      // Add month to list
      months.push(date)

      // Increment month
      date = addMonths(date, 1)
    }
  }

  return months
}

/**
 * Convert *HH:mm* time string
 * into number of minutes
 * @param {string} text
 */
export function getTimeMinutes(text) {
  if (timeStrRegexp.test(text)) {
    const hours = parseInt(text.substr(0, 2))
    const minutes = parseInt(text.substr(3, 2))

    return hours * 60 + minutes
  }
  return -1
}

/**
 * Check if *Date* object is valid,
 * ref: https://stackoverflow.com/a/1353711
 * @param {Date} date
 */
export function isDateValid(date) {
  return date instanceof Date && !isNaN(date)
}

/**
 * Pad leading zero to format 2-digit time string
 * @param {number} time
 */
export function padTimeZero(time) {
  return _padStart(time, 2, '0')
}
