<template>
  <b-icon-text
    slot="button"
    :active="active"
    :title="buttonTitle"
    @click="onClick"
  >
    <svg-add slot="icon" class="b__icon svg-fill" />
  </b-icon-text>
</template>

<script>
import IconTextButton from '../button/iconTextButton'
import SvgAdd from '../../assets/svg/add.svg?inline'

export default {
  components: {
    'b-icon-text': IconTextButton,
    'svg-add': SvgAdd,
  },
  computed: {
    /**
     * Post job title
     */
    buttonTitle() {
      return this.$t('postJob.title')
    },
  },
  methods: {
    /**
     * Emit click event
     */
    onClick() {
      this.$emit('click')
    },
  },
  props: {
    /**
     * Whether button is active
     */
    active: {
      default: true,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
.b.b--icon_text {
  & .b__icon {
    @apply mr-2;
  }
  & >>> .b__title {
    @apply font-bold text-red-500;
    line-height: 24px;
  }
}

/* Default button */
.b.b--icon_text:not(.b--inactive) {
  @apply border border-red-500;
}

@screen mobile {
  .b.b--icon_text {
    @apply pr-5;
    height: 42px;
    padding-left: 0.875rem;
    border-radius: 21px;

    &:after {
      border-radius: 21px;
    }
  }

  /* Default button */
  .b.b--icon_text:not(.b--inactive) {
    @apply text-red-500 bg-white-plain;

    & .b__icon {
      @apply text-red-500;
    }
  }
}

@screen desktop {
  .b.b--icon_text {
    @apply pl-4 pr-6 bg-white;
    height: 48px;
    border-radius: 24px;

    &:hover {
      @apply bg-red-50;
    }
    &:after {
      border-radius: 24px;
    }
    & .b__icon {
      @apply w-b h-b;
    }
  }

  /* Default button */
  .b.b--icon_text:not(.b--inactive) {
    & .b__icon {
      @apply text-red-500;
    }
  }
}
</style>
