/**
 * NuxtJS middleware to redirect logged-in user
 * to create profile-related pages
 */
export default function ({ redirect, route, store }) {
  // Only check for user profile
  // when user is logged in
  if (store.getters['auth/isLoggedIn']) {
    // Whether user profile is created
    const isProfileCreated = store.getters['profile/isCreated']

    // Whether target route is create profile
    const isCreateProfileRoute = route.path === '/profile/create/'

    // Whether target route is upload BR
    const isUploadBRRoute = route.path === '/profile/upload-br/'

    // Whether target route is upload logo
    const isUploadLogoRoute = route.path === '/profile/upload-logo/'

    if (!isProfileCreated && !isCreateProfileRoute) {
      // If target path not equal to '/',
      // pass as query for redirection on login success
      const redirectQuery =
        route.fullPath !== '/' ? { target: route.fullPath } : {}

      // Redirect to create profile page if:
      // 1) profile not created yet, and
      // 2) target route is not create profile
      redirect('/profile/create/', redirectQuery)
    } else if (isProfileCreated && isCreateProfileRoute) {
      // Redirect to home page if:
      // 1) profile created already, and
      // 2) target route is create profile
      redirect('/')
    } else if (isUploadBRRoute && !!store.getters['profile/brCert']) {
      // Redirect to home page if:
      // 1) BR cert uploaded already, and
      // 2) target route is upload BR
      redirect('/')
    } else if (isUploadLogoRoute && !!store.getters['profile/companyLogo']) {
      // Redirect to home page if:
      // 1) Logo uploaded already, and
      // 2) target route is upload logo
      redirect('/')
    }
  }
}
