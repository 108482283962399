<template>
  <div class="load">
    <svg-loading-bg class="load__bg" />
  </div>
</template>

<script>
import SvgLoadingBg from '../assets/svg/loading.svg?inline'

export default {
  components: {
    'svg-loading-bg': SvgLoadingBg,
  },
}
</script>

<style lang="postcss" scoped>
.load {
  @apply flex justify-center items-center relative;
  width: 70px;
  height: 70px;
}
.load__bg {
  @apply absolute inset-0 z-0;
  animation: rotate 1.5s linear infinite;
}
.load__icon {
  @apply relative z-1;
  width: 60px;
  height: 87px;

  & svg {
    @apply w-full h-full;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
