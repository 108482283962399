import Loading from '../../components/loading'
import OverlayMixin from './'

/**
 * Mixin for loading overlay
 */
export default {
  components: {
    loading: Loading,
  },
  computed: {
    /**
     * Whether page is currently loading
     */
    isLoading() {
      return this.$store.state.loading.isLoading
    },
  },
  created() {
    // Show loading overlay
    // if page is already loading
    this.toggleLoadingOverlay(this.isLoading)
  },
  methods: {
    /**
     * Toggle showing loading overlay
     */
    toggleLoadingOverlay(isLoading = false) {
      if (isLoading) {
        this.showOverlay('loading')
      } else {
        this.closeOverlay()
      }
    },
  },
  mixins: [OverlayMixin],
  watch: {
    /**
     * Show / close loading overlay
     * based on page loading state
     */
    isLoading(isLoading) {
      this.toggleLoadingOverlay(isLoading)
    },
  },
}
