import Tracker from '.'

/**
 * Firebase Analytics tracker,
 * uses *firebase SDK* for sending tracking events,
 * ref: https://firebase.nuxtjs.org/guide/introduction/
 */
export default class FirebaseTracker extends Tracker {
  /**
   * @param {string} version
   * @param {string} locale
   */
  constructor(fireAnalytics, version, locale) {
    super()

    if (fireAnalytics) {
      // Set firebase analytics instance
      this._fireAnalytics = fireAnalytics

      // Set app version and language
      this.setUserProperties({ version })
      this.setLanguage(locale)
    }
  }

  /**
   * Send firebase analytics custom event
   * @param {string} eventName
   */
  sendCustomEvent(eventName, eventVariables) {
    if (this._fireAnalytics) {
      this._fireAnalytics.logEvent(eventName, eventVariables)
    }
  }

  /**
   * Send firebase analytis pageview event
   * as screen view
   * @param {string} screenName
   * @param {string} pageLocation
   * @param {string} pagePath
   */
  sendPageViewEvent(screenName, pageLocation, pagePath) {
    if (screenName && this._fireAnalytics) {
      // Set current screen name
      this._fireAnalytics.setCurrentScreen(screenName)

      // Send screen view event
      this.sendCustomEvent('screen_view', {
        page_location: pageLocation,
        page_path: pagePath,
        page_title: screenName,
        screen_name: screenName,
      })
    }
  }

  /**
   * Set firebase analytics app language
   * @param {string} locale
   */
  setLanguage(locale) {
    this.setUserProperties({
      in_app_language: locale,
    })
  }

  /**
   * Set firebase analytics user ID
   * @param {string} userId
   */
  setUserId(userId) {
    if (this._fireAnalytics) {
      this._fireAnalytics.setUserId(userId)
    }
  }

  /**
   * Set firebase analytics user properties
   */
  setUserProperties(properties) {
    if (this._fireAnalytics) {
      this._fireAnalytics.setUserProperties(properties)
    }
  }
}
