<template>
  <div class="ods-header">
    <svg-moovup-logo-mobile class="moovup-logo-mobile" />
    <div class="ods-header-btn">
      <p class="font-bold mr-1">{{ $t('ods.mobileLabel') }}</p>
      <a :href="`${odsUrl}?utm_source=employer_portal&utm_medium=web`" target="_self" class="ods-btn">{{
        $t('ods.mobileButton')
      }}</a>
    </div>
  </div>
</template>

<script>
import MoovupLogoMobile from '../../assets/svg/moovupLogo_mobile.svg'

export default {
  components: {
    'svg-moovup-logo-mobile': MoovupLogoMobile,
  },
  data() {
    return {
      odsUrl: process.env.EMPLOYER_WEB__ODS_URL,
    }
  },
}
</script>

<style scoped lang="postcss">
.ods-header {
  @apply items-center justify-between px-4 py-2 flex bg-white shadow-xs z-30;
}

.moovup-logo-mobile {
  width: 40px;
}

.ods-header-btn {
  @apply flex items-center;
}

.ods-btn {
  @apply bg-indigo-500 py-2 px-3 rounded-lg text-white text-sm no-underline font-bold;
}

@screen desktop {
  .ods-header {
    @apply hidden;
  }
}
</style>
