<template>
  <overlay :show="showSelectedLanguagePopup">
    <popup
      v-show="showSelectedLanguagePopup"
      small
      alert
      class="display-language-popup"
    >
      <div slot="main" class="display-language-popup__main form__main">
        <div class="display-language-popup__heading">
          <svg-language class="display-language-popup__icon" />
          <h2 class="display-language-popup__title">Select Language</h2>
        </div>
        <b-icon-text
          title="繁體中文"
          class="display-language-popup__btn display-language-popup__btn-zh-hant"
          @click="selectLanguage('zh-hant')"
        >
          <svg-arrow-down slot="icon" class="arrow-down" />
        </b-icon-text>
        <b-icon-text
          title="English"
          class="display-language-popup__btn"
          @click="selectLanguage('en')"
        >
          <svg-arrow-down slot="icon" class="arrow-down" />
        </b-icon-text>
      </div>
    </popup>
  </overlay>
</template>

<script>
import GraphqlFormMixin from '../mixins/form/graphql'
import IconTextButton from '../components/button/iconTextButton'
import Overlay from './overlay'
import Popup from './popup/popup'
import { postEmployerGQL } from '../api/octo/graphql'
import SvgArrowDown from '../assets/svg/arrowDown.svg'
import SvgLanguage from '../assets/svg/language.svg'
import { UPDATE_EMPLOYER_SETTING } from '../api/octo/graphql/mutations/setting'

export default {
  components: {
    'b-icon-text': IconTextButton,
    overlay: Overlay,
    popup: Popup,
    'svg-arrow-down': SvgArrowDown,
    'svg-language': SvgLanguage,
  },
  computed: {
    showSelectedLanguagePopup() {
      return (
        this.$store.getters['profile/displayLanguage'] === null &&
        !this.hasClickedBtn
        // FIXME: We received few bug reports from user saying that no response after clicking the button,
        // to quick fix this issue, we let us to dismiss the popup no matter the API call fails or not
      )
    },
  },
  data() {
    return {
      hasClickedBtn: false,
    }
  },
  methods: {
    async selectLanguage(language) {
      this.setAppLocale(language, '')
      this.hasClickedBtn = true
      const res = await postEmployerGQL(
        UPDATE_EMPLOYER_SETTING,
        {
          display_language: language,
        },
        this.token,
      )
      if (res && res.data && res.data.update_employer_setting) {
        this.$store.commit('profile/updateUserDisplayLanguage', language)
      }
    },
  },
  mixins: [GraphqlFormMixin],
}
</script>

<style scoped lang="postcss">
.display-language-popup__heading {
  @apply flex mb-6;
}
.arrow-down {
  width: 10px;
}
.display-language-popup >>> .popup__content {
  @apply px-4 py-6;
}
.display-language-popup__main {
  & >>> .b:not(.b--row) .b__title {
    @apply text-base;
  }
}
.display-language-popup__icon {
  width: 24px;
  @apply mr-1;
}
.display-language-popup__title {
  @apply text-base;
  margin-bottom: 2px;
}
.display-language-popup__btn {
  max-width: 100%;
  @apply border border-grey-900 w-full;
  .b__title {
    @apply text-grey-900;
  }
  &.b {
    justify-content: start;
    @apply flex-row-reverse justify-between;
  }
  .arrow-down {
    transform: rotate(-90deg);
    & path {
      fill: #d4d4d4;
    }
  }
}
.display-language-popup__btn-zh-hant {
  @apply mb-2;
}
</style>
