/**
 * Tracking platform IDs
 */
export const trackingPlatforms = {
  fbAnalytics: 'fb-analytics',
  fbPixel: 'fb-pixel',
  firebase: 'firebase',
  gaAds: 'ga-ads',
  gaWeb: 'ga-web',
}

/**
 * Tracker interface
 */
export default class Tracker {
  /**
   * @param {string} id
   */
  constructor(id) {
    this._id = id
    this._deferInitDone = false
  }

  /**
   * Handle deferred initialisation
   */
  deferInit() {
    this._deferInitDone = true
  }

  /**
   * Send conversion
   * @param {string} conversionId
   */
  sendConversion(conversionId) {}

  /**
   * Send custom event
   * @param {string} eventName
   */
  sendCustomEvent(eventName, eventVariables) {}

  /**
   * Send pageview event
   * @param {string} screenName
   * @param {string} pageLocation
   * @param {string} pagePath
   */
  sendPageViewEvent(screenName, pageLocation, pagePath) {}

  /**
   * Set app language to tracker context
   * @param {string} locale
   */
  setLanguage(locale) {}

  /**
   * Set user ID to tracker context
   * @param {string} userId
   */
  setUserId(userId) {}

  /**
   * Set user properties to tracker context
   */
  setUserProperties(properties) {}
}
