<template>
  <b-icon :link-path="path" :link-query="query" :title="$t('link.back')">
    <svg-back slot="icon" />
  </b-icon>
</template>

<script>
import IconButton from '../button/iconButton'
import SvgBack from '../../assets/svg/back.svg?inline'

export default {
  components: {
    'b-icon': IconButton,
    'svg-back': SvgBack,
  },
  props: {
    /**
     * Route path
     */
    path: {
      default: '/',
      type: String,
    },

    /**
     * Route query
     */
    query: {
      default: () => {},
      type: Object,
    },
  },
}
</script>
