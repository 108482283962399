import { render, staticRenderFns } from "./postJobButton.vue?vue&type=template&id=3b2c7631&scoped=true&"
import script from "./postJobButton.vue?vue&type=script&lang=js&"
export * from "./postJobButton.vue?vue&type=script&lang=js&"
import style0 from "./postJobButton.vue?vue&type=style&index=0&id=3b2c7631&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b2c7631",
  null
  
)

export default component.exports