<template>
  <header class="hdr">
    <app-header-nav />
  </header>
</template>

<script>
import AppHeaderNav from './appHeaderNav'

export default {
  components: {
    'app-header-nav': AppHeaderNav,
  },
}
</script>

<style lang="postcss" scoped>
.hdr {
  /* Sticky header, fallback to position: relative */
  @apply relative sticky top-0 left-0;
  @apply w-full h-h text-pink bg-white z-10;
}
</style>
