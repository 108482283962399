import { captureException } from '@sentry/browser'
import { getMSecTimestamp } from './datetime'
import jwtDecode from 'jwt-decode'

/** Default token issuer name */
const TOKEN_ISSUER = 'moovup'

/**
 * Try to decode token,
 * return token payload if success
 * @param {string} token
 */
export function decodeToken(token) {
  try {
    if (token) {
      const payload = jwtDecode(token)

      // Validate token issuer and expiry date,
      // and verify token is issued to an employer
      if (payload && payload.iss === TOKEN_ISSUER && !isTokenExpired(payload)) {
        return payload
      }
    }
  } catch (err) {
    captureException(err)
  }
}

/**
 * Check if token is employer-only or not:
 * 1) Token payload available,
 * 2) Employer flag set to *true*, and
 * 3) Anonymous flag *not* set to true
 */
export function isEmployerToken(payload) {
  return !!payload && !!payload['employer?'] && !payload['anonymous?']
}

/**
 * Check if token is email login or not:
 */
export function isEmailLogin(payload) {
  return !!payload && payload['email?']
}

/**
 * Check if token is phone login or not:
 */
export function isPhoneLogin(payload) {
  return !!payload && payload['phone?']
}

/**
 * Check if token is expired or not
 */
export function isTokenExpired(payload) {
  return (
    !!payload && !!payload.exp && Date.now() > getMSecTimestamp(payload.exp)
  )
}

/**
 * *local storage* key for employer portal token
 */
export const LS_TOKEN_KEY = 'moovup_ep_token'
