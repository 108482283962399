import Overlay from '../../components/overlay'

/**
 * Mixin for overlays
 */
export default {
  components: {
    overlay: Overlay,
  },
  computed: {
    /**
     * Whether overlay is currently active
     */
    isOverlayActive() {
      return !!this.overlay
    },
  },
  data() {
    return {
      /**
       * Active overlay name
       */
      overlay: '',
      /**
       * Intent for open overlay
       */
      overlayIntent: '',
    }
  },
  methods: {
    /**
     * Unset active overlay name
     */
    closeOverlay() {
      this.overlay = ''
      this.overlayIntent = ''
    },

    /**
     * Set active overlay name
     * based on provided name
     * @param {string} overlay
     */
    showOverlay(overlay, intent) {
      if (overlay) {
        this.overlay = overlay
        this.overlayIntent = intent
      }
    },
  },
}
