import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _map from 'lodash/map'

/**
 * Edit job *Vuex* module getters
 */
export default {
  /**
   * Extract *graphql* job arguments
   * from edit job cache
   */
  gqlJobArguments(state) {
    // Get edit job cache
    const args = _cloneDeep(state.job)

    // Extract *_id* field from these list fields
    args.attributes = _map(args.attributes, '_id')
    args.job_types = _map(args.job_types, '_id')

    // Extract *_id* field from these fields
    args.education_requirement = _get(args.education_requirement, '_id')

    // Map addresses
    if (Array.isArray(args.address)) {
      args.address.forEach((address) => {
        // Set district _id field
        address.district = address.district_id || ''

        // Delete address data fields
        delete address._id
        delete address.district_id
        delete address.district_name
      })
    }

    // Delete *career_level* enum field if empty,
    // related issue: https://github.com/graphql-python/graphql-core/issues/201
    if (!args.career_level) {
      delete args.career_level
    }

    return args
  },

  /**
   * Extract *graphql* company profile arguments
   * from edit profile cache
   */
  gqlProfileArguments(state) {
    // Get edit profile cache
    return _cloneDeep(state.profile)
  },

  /**
   * Check if post job step is completed
   * based on provided index
   * @readonly
   */
  isStepCompleted(state) {
    /** @param {number} stepIndex */
    const func = (stepIndex) => !!state.stepsCompleted[stepIndex]
    return func
  },

  /**
   * Index of next incomplete post job step,
   * i.e. earliest incomplete step
   * @returns {number}
   * @readonly
   */
  stepNextIncomplete(state) {
    return state.stepsCompleted.findIndex((step) => !step)
  },
}
