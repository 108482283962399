<template>
  <ol class="m m--list" :class="popup ? 'm--list-popup' : ''">
    <template v-for="(item, i) in items">
      <!-- Menu separator -->
      <span
        v-if="i > 0"
        :key="`MenuSeparator${i}`"
        class="m__separator section-break"
      />
      <!-- Menu item -->
      <li :key="`MenuItem${i}`" class="m__item">
        <b-list
          v-bind="item"
          class="b--full"
          :active="!isItemLinkCurrent(item)"
          @click="onItemClick(i)"
        >
          <h5 v-if="item.new" slot="label">
            {{ $t('jobPromotion.newFeature') }}
          </h5>
        </b-list>
      </li>
    </template>
  </ol>
</template>

<script>
import ListButton from '../button/listButton'

export default {
  components: {
    'b-list': ListButton,
  },
  methods: {
    /**
     * Whether menu item link is current route
     */
    isItemLinkCurrent(item) {
      return item && item.linkPath === this.$route.fullPath
    },

    /**
     * Emit menu item click event
     * w/ item index
     */
    onItemClick(index) {
      if (index >= 0 && index < this.items.length) {
        this.$emit('click', index)
      }
    },
  },
  props: {
    /**
     * Menu items
     */
    items: {
      default: () => [],
      type: Array,
    },

    /**
     * Whether to use popup layout
     */
    popup: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
h5 {
  @apply px-2 py-1 ml-2 mb-1 rounded-md rounded-bl-none;
  @apply bg-pink text-white text-xs;
}

.m__item {
  @apply overflow-hidden;
}
.m__separator {
  @apply mx-4;
  width: calc(100% - 2rem);
}

/* Current menu link */
.b.b--menu.b--inactive {
  &:after {
  }
  & >>> .b__title {
    @apply font-bold;
  }
}

/* Popup list layout */
.m.m--list-popup {
  @apply rounded-md bg-white-plain shadow border border-grey-400;

  & .m__item:first-child {
    @apply rounded-t-md;
  }
  & .m__item:last-child {
    @apply rounded-b-md;
  }
  & .b.b--menu,
  & .b.b--menu:after {
    @apply rounded-none;
  }
}

@screen desktop {
  .m__separator {
    @apply mx-5;
    width: calc(100% - 2.5rem);
  }
}
</style>
