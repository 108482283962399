/**
 * Edit job *Vuex* module state
 */
export default function () {
  return {
    /**
     * Edit job cache
     */
    job: {},

    /**
     * Edit company profile cache
     */
    profile: {},

    /**
     * Post job completed steps
     */
    stepsCompleted: [],
  }
}
