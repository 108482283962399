<template>
  <form class="popup form" novalidate :class="popupClasses" @submit.prevent>
    <!-- Banner slot -->
    <div v-if="showBanner" class="popup__banner">
      <slot name="banner" />
    </div>
    <!-- Main container -->
    <div class="popup__main">
      <!-- Header -->
      <popup-header
        v-if="showHeader"
        :back-path="backPath"
        :back-query="backQuery"
        :show-back="showBack"
        :show-close="showClose"
        :show-masthead="showMasthead"
      >
        <!-- Header title slot -->
        <slot slot="headerTitle" name="headerTitle" />
      </popup-header>
      <!-- Main content slot -->
      <section class="popup__content">
        <slot name="main" />
      </section>
      <!-- Actions slot -->
      <section v-if="showActions" class="popup__actions">
        <slot name="actions" />
      </section>
    </div>
    <!-- Snackbar overlay -->
    <snack :title="snackTitle" @close="snackClose" @show="snackShow" />
    <!-- Overlays slot -->
    <slot name="overlay" />
  </form>
</template>

<script>
import PopupHeader from './popupHeader'
import Snackbar from '../snackbar'

export default {
  components: {
    'popup-header': PopupHeader,
    snack: Snackbar,
  },
  computed: {
    /**
     * Popup component classes
     */
    popupClasses() {
      let classes = this.small ? 'popup--sm' : ''
      if (this.fullPage) {
        classes += `${classes ? ' ' : ''}popup--full`
      }
      if (this.alert) {
        classes += `${classes ? ' ' : ''}popup--alert`
      }
      return classes
    },

    /**
     * Only show popup actions when slot content available
     */
    showActions() {
      return !!this.$slots.actions
    },

    /**
     * Only show popup banner when slot content available
     */
    showBanner() {
      return !!this.$slots.banner
    },

    /**
     * Whether to show popup header
     */
    showHeader() {
      return (
        this.showMasthead ||
        this.showHeaderTitle ||
        this.showClose ||
        this.showBack ||
        this.backPath
      )
    },

    /**
     * Only show popup header title when slot content available
     */
    showHeaderTitle() {
      return !!this.$slots.headerTitle
    },
  },
  methods: {
    /**
     * Handle snackbar close
     */
    snackClose() {
      this.$emit('snack-close')
    },

    /**
     * Handle snackbar show
     */
    snackShow() {
      this.$emit('snack-show')
    },
  },
  props: {
    /**
     * Whether to use alert layout
     */
    alert: {
      default: false,
      type: Boolean,
    },

    /**
     * Route path for back link
     */
    backPath: {
      default: '',
      type: String,
    },

    /**
     * Route query for back link
     */
    backQuery: {
      default: () => {},
      type: Object,
    },

    /**
     * Whether to use full page layout
     */
    fullPage: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to show back button
     */
    showBack: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to show close button
     */
    showClose: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to show masthead
     */
    showMasthead: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether to use small layout
     */
    small: {
      default: false,
      type: Boolean,
    },

    /**
     * Snackbar alert title
     */
    snackTitle: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.popup {
  @apply flex w-full mx-auto;
}

/* Popup main content */
.popup__main {
  @apply flex-1 flex flex-col items-center;
  @apply w-full overflow-x-hidden;

  & > * {
    @apply flex-shrink-0;
  }
}
.popup__content {
  @apply w-full;
  background-color: white;

  /* Top & bottom flex padding */
  &:before,
  &:after {
    @apply block w-full;
    content: '';
  }
}
.popup__actions {
  @apply w-full z-2;
  background-color: white;
}

/* Popup banner */
.popup__banner {
  @apply relative w-full;
}

/* Default popup layout */
.popup:not(.popup--full) {
  @apply h-full;
}

/* Small popup layout */
.popup.popup--sm {
  & > .popup__main > .popup-hdr {
    @apply pb-0;
  }
}

/* Alert layout */
.popup.popup--alert {
  @apply flex-col items-center;

  & > .popup__main > .popup__content:after {
    @apply h-0; /* Bottom flex padding */
  }
}

@screen mobile {
  .popup {
    @apply flex-col items-center bg-white;
  }

  .popup__main > * {
    @apply px-4;
  }
  .popup-hdr {
    @apply py-4;
    max-width: 532px;
  }
  .popup__content {
    @apply max-w-form;

    &:before {
      @apply h-2; /* Top flex padding */
    }
    &:after {
      @apply h-6; /* Bottom flex padding */
    }
  }
  .popup__actions {
    @apply max-w-form pt-10 pb-6;
  }

  /* Small popup layout */
  .popup.popup--sm {
    & > .popup__main > .popup-hdr {
      @apply pt-2;
    }
    & > .popup__main > .popup__actions {
      @apply pt-6;
    }
  }

  /* Alert layout */
  .popup.popup--alert {
    & > .popup__main > .popup__content:after {
      @apply h-6; /* Top flex padding */
    }
    & > .popup__main > .popup__actions {
      @apply pt-6;
    }
  }
}

@screen desktop {
  /* Popup main content */
  .popup__main {
    @apply bg-white;
  }
  .popup__main > * {
    @apply px-8;
  }
  .popup-hdr {
    @apply pt-10;
  }
  .popup__content {
    @apply flex-grow flex-shrink min-h-0 overflow-y-auto;

    &:before {
      @apply h-10; /* Top flex padding */
    }
    &:after {
      @apply h-10; /* Bottom flex padding */
    }
  }
  .popup__actions {
    &:before {
      @apply block w-full h-px;
      content: '';
    }
  }

  /* Default popup layout */
  .popup:not(.popup--full) {
    @apply justify-center;
    width: 800px;
    max-height: 660px;

    & > .popup__main {
      @apply max-w-form-desktop rounded-xl shadow;
    }
    & > .popup__main > .popup__actions {
      @apply pb-6;
    }
    & > .popup__banner {
      @apply flex-1 h-full rounded-l-xl overflow-hidden;
    }
    & > .snack {
      left: calc(50% - 275px);
    }
  }

  /* Small popup layout */
  .popup.popup--sm {
    & > .popup__main > .popup-hdr {
      @apply pt-4;
    }
    & > .popup__main > .popup__content:before {
      @apply h-4; /* Top flex padding */
    }
    & > .popup__main > .popup__actions {
      @apply pb-5;
    }
    & > .popup__main > .popup__actions:before {
      @apply mb-4 bg-grey-200;
    }
  }

  /* Full page layout */
  .popup.popup--full {
    @apply flex-col items-center px-4;

    & > .popup__main {
      @apply max-w-form-app-desktop;
      @apply border-l border-r border-solid border-pink-medium;
    }
    & > .popup__main > * {
      @apply px-10;
    }
  }

  /* Post job layout */
  .popup.popup--post {
    & > .popup__main > .popup-hdr {
      @apply hidden;
    }
    & > .popup__main > .popup__content:before {
      @apply h-10; /* Top flex padding */
    }
  }

  /* Alert layout */
  .popup.popup--alert {
    @apply h-auto;

    & > .popup__main > .popup-hdr::v-deep > .popup-hdr__title {
      @apply justify-start px-2;
    }
    & > .popup__main > .popup__content:after {
      @apply h-4; /* Bottom flex padding */
    }
    & > .popup__main > .popup__actions {
      @apply pb-6;
    }
    & > .popup__main > .popup__actions:before {
      @apply hidden;
    }
  }

  /* Popup in pages under login, password and signup folders */
  .login .popup {
    width: 400px;
  }

  .password .popup {
    width: 400px;
  }

  .signup .popup {
    width: 400px;
  }
}
</style>
