/**
 * Handle injecting script into document
 * @param {string} src
 * @param {boolean} isDefer Whether script should be executed after document ready
 */
export function injectScript(src, isDefer = false) {
  if (typeof document === 'undefined') {
    return
  }

  // Create new script tag
  const tag = document.createElement('script')
  tag.async = true
  tag.defer = isDefer
  tag.src = src

  // Insert the tag before first script tag in document
  const tag1 = document.getElementsByTagName('script')[0]
  if (tag1 && tag1.parentNode) {
    tag1.parentNode.insertBefore(tag, tag1)
  }
}
