/**
 * Locale keys for form messages
 */
export default {
  /** Add success */
  ADD_SUCCESS: 'addSuccess',

  /** Application comment success */
  APP_COMMENT_SUCCESS: 'appCommentSuccess',

  /** Application reject success */
  APP_REJECT_SUCCESS: 'appRejectSuccess',

  /** Change email success */
  CHANGE_EMAIL_SUCCESS: 'changeEmailSuccess',

  CHANGE_LANGUAGE_SUCCESS: 'changeLanguageSuccess',

  /** Change mobile success */
  CHANGE_MOBILE_SUCCESS: 'changeMobileSuccess',

  /** Change password success */
  CHANGE_PASSWORD_SUCCESS: 'changePasswordSuccess',

  /** Delete success */
  DELETE_SUCCESS: 'deleteSuccess',

  /** Job close success */
  JOB_CLOSE_SUCCESS: 'jobCloseSuccess',

  /** Job pause success */
  JOB_PAUSE_SUCCESS: 'jobPauseSuccess',

  /** Job post success */
  JOB_POST_SUCCESS: 'jobPostSuccess',

  /** Resend email success */
  RESEND_EMAIL_SUCCESS: 'resendEmailSuccess',

  /** Save success */
  SAVE_SUCCESS: 'saveSuccess',
}
