<template>
  <aside class="side">
    <!-- Post job button -->
    <section class="side__actions">
      <m-side-post-job
        class="side__post-job"
        :company-suspended="companySuspended"
      />
    </section>
    <!-- Sidebar container -->
    <section class="side__container">
      <side-header />
      <side-nav
        :company-suspended="companySuspended"
        :unread-count="unreadCount"
      />
    </section>
  </aside>
</template>

<script>
import SidebarHeader from './sidebarHeader'
import SidebarNav from './sidebarNav'
import SidebarPostJobMenu from './sidebarPostJobMenu'

export default {
  components: {
    'm-side-post-job': SidebarPostJobMenu,
    'side-header': SidebarHeader,
    'side-nav': SidebarNav,
  },
  computed: {
    /**
     * Whether company is suspended
     */
    companySuspended() {
      return this.$store.getters['profile/companySuspended']
    },

    unreadCount() {
      if (this.companySuspended) {
        return 0
      }
      return this.$store.getters['profile/totalUnreadApplicationCount']
    },
  },
}
</script>

<style lang="postcss" scoped>
.side {
  @apply relative;
}

/* Sidebar post job button */
.side__actions {
  @apply absolute;
}

/* Sidebar container */
.side__container {
  @apply relative w-full bg-white;
}

@screen mobile {
  .side {
    @apply w-full;
  }

  /* Sidebar post job button */
  .side__actions {
    @apply right-0 pointer-events-none;
    @apply pb-3 pr-3 z-0;
    bottom: 100%;
  }
  .side__post-job {
    @apply pointer-events-auto;
    transition: transform 0.3s ease 0.4s, opacity 0.3s ease 0.4s;
  }

  /* Sidebar container */
  .side__container {
    @apply h-f-app shadow-top z-1;
  }
  .side-hdr {
    @apply hidden;
  }
}

@screen desktop {
  .side {
    @apply h-full;
    width: 240px;
  }

  /* Sidebar post job button */
  .side__actions {
    @apply top-app left-0;
    @apply pt-2 pl-4 z-1;
  }

  /* Sidebar container */
  .side__container {
    @apply h-full shadow-right z-0;
  }
  .side-nav {
    margin-top: 4.5rem;
  }
}
</style>
