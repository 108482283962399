/**
 * Loading *Vuex* module mutations
 */
export default {
  /**
   * Toggle page loading state
   */
  toggleLoading(state, isLoading = false) {
    state.isLoading = !!isLoading
  },
}
