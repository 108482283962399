import { render, staticRenderFns } from "./defaultButton.vue?vue&type=template&id=25fa2013&scoped=true&"
import script from "./defaultButton.vue?vue&type=script&lang=js&"
export * from "./defaultButton.vue?vue&type=script&lang=js&"
import style0 from "./defaultButton.vue?vue&type=style&index=0&id=25fa2013&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25fa2013",
  null
  
)

export default component.exports