import { captureException } from '@sentry/core'
import { requestIdleCallback } from '../timeout'

/**
 * Event tracker library
 */
export default class EventTracker {
  constructor(trackers) {
    this._trackers = trackers
  }

  /**
   * Initialise event trackers asynchronously,
   * i.e. when browser is idle
   * @param {[string]} trackerIds
   */
  deferInit(trackerIds) {
    this.getTrackers(trackerIds).forEach((tracker) => {
      requestIdleCallback(() => {
        tracker.deferInit()
      })
    })
  }

  /**
   * Get tracker instances based on provided IDs,
   * return all trackers when none provided
   * @param {[string]} trackerIds
   */
  getTrackers(trackerIds) {
    if (Array.isArray(trackerIds) && trackerIds.length) {
      return trackerIds
        .map((id) => this._trackers[id])
        .filter((tracker) => tracker)
    }
    return Object.values(this._trackers)
  }

  /**
   * Log event missing param error
   * @param {string} eventName
   * @param {string} paramName
   */
  logMissingParam(eventName, paramName) {
    if (eventName && paramName) {
      captureException(
        new Error(`Event "${eventName}": missing param "${paramName}"`),
      )
    }
  }

  /**
   * Send conversion to trackers
   * @param {string} conversionId
   * @param {[string]} trackerIds
   */
  sendConversion(conversionId, trackerIds) {
    this.getTrackers(trackerIds).forEach((tracker) => {
      tracker.sendConversion(conversionId)
    })
  }

  /**
   * Send custom event to trackers
   * @param {string} eventName
   * @param {[string]} requiredParams
   * @param {[string]} trackerIds
   */
  sendCustomEvent(
    eventName,
    eventVariables = {},
    requiredParams = [],
    trackerIds,
  ) {
    if (eventName) {
      // Check if any of the required params are missing
      let paramMissing = false
      requiredParams.forEach((paramName) => {
        const param = eventVariables[paramName]
        if (!param && !Number.isFinite(param)) {
          paramMissing = true

          // Log missing param error
          this.logMissingParam(eventName, paramName)
        }
      })

      // If all required params are avaiable,
      // send custom events
      if (!paramMissing) {
        this.getTrackers(trackerIds).forEach((tracker) => {
          tracker.sendCustomEvent(eventName, eventVariables)
        })
      }
    }
  }

  /**
   * Send pageview event to trackers
   * @param {string} screenName
   * @param {string} pageLocation
   * @param {string} pagePath
   * @param {[string]} trackerIds
   */
  sendPageViewEvent(screenName, pageLocation, pagePath, trackerIds) {
    if (pagePath) {
      this.getTrackers(trackerIds).forEach((tracker) => {
        tracker.sendPageViewEvent(screenName, pageLocation, pagePath)
      })
    }
  }

  /**
   * Set app language to tracker context
   * @param {[string]} trackerIds
   */
  setLanguage(locale = '', trackerIds) {
    this.getTrackers(trackerIds).forEach((tracker) => {
      tracker.setLanguage(locale)
    })
  }

  /**
   * Set user ID to tracker context
   * @param {[string]} trackerIds
   */
  setUserId(userId = '', trackerIds) {
    this.getTrackers(trackerIds).forEach((tracker) => {
      tracker.setUserId(userId)
    })
  }

  /**
   * Set user properties to tracker context
   * @param {[string]} trackerIds
   */
  setUserProperties(properties = {}, trackerIds) {
    this.getTrackers(trackerIds).forEach((tracker) => {
      tracker.setUserProperties(properties)
    })
  }
}
