<template>
  <m-drop-down :active="!companySuspended" :items="items">
    <b-post-job slot="button" :active="!companySuspended" />
  </m-drop-down>
</template>

<script>
import DropDownMenu from '../menu/dropDownMenu'
import PostJobButton from '../button/postJobButton'

export default {
  components: {
    'b-post-job': PostJobButton,
    'm-drop-down': DropDownMenu,
  },
  computed: {
    /**
     * Menu items
     */
    items() {
      const createJobPath = '/jobs/post/info/'
      return [
        {
          linkPath: createJobPath,
          linkQuery: {
            et: 'full-time',
            event_source: this.eventSource,
          },
          title: this.$t('employmentType.FullTime'),
        },
        {
          linkPath: createJobPath,
          linkQuery: {
            et: 'part-time',
            event_source: this.eventSource,
          },
          title: this.$t('employmentType.PartTime'),
        },
        {
          linkPath: createJobPath,
          linkQuery: {
            et: 'temp',
            event_source: this.eventSource,
          },
          title: this.$t('employmentType.Temp'),
        },
      ]
    },
  },
  data() {
    return {
      /**
       * Event source
       */
      eventSource: 'nav.post_job_button',
    }
  },
  props: {
    /**
     * Whether company is suspended
     */
    companySuspended: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
@screen mobile {
  .m.m--dropdown >>> .m__list {
    @apply top-auto left-auto right-0 pt-0 pb-2;
    bottom: 100%;
  }
  .m.m--dropdown:not(:hover) >>> .m__list {
    transform: translateY(3rem);
  }
}
</style>
