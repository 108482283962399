import { getLinkByKey, LinkKeys } from '../libs/mapping/link'
import AuthMixin from './auth'
import GraphqlFormMixin from '../mixins/form/graphql'
import messageKeys from '../libs/messageKeys'
import { UPDATE_EMPLOYER_SETTING } from '../api/octo/graphql/mutations/setting'

/**
 * Mixin for menus
 */
export default {
  computed: {
    /**
     * Alternative locale menu items
     */
    altLocaleItems() {
      return this.altLocales.map((locale) => ({
        'data-action': 'locale',
        'data-locale': locale.code,
        title: locale.name,
      }))
    },
    gqlVariables() {
      if (this.gqlOperation === 'updateEmployerSetting') {
        return this.gqlUpdateEmployerSettingVariables
      }
      return {}
    },
    /**
     * Logout menu item
     */
    logoutItem() {
      return {
        'data-action': 'logout',
        title: this.$t('logout.actionMain'),
      }
    },

    /**
     * Settings menu items
     */
    settingsItems() {
      return [
        {
          linkPath: '/account/',
          title: this.$t('account.accountInfo'),
        },
      ]
    },

    /**
     * Support menu items
     */
    supportItems() {
      return [
        {
          // External link
          href: getLinkByKey(this.currentLocale.code, LinkKeys.faq),
          title: this.$t('link.faq'),
        },
        {
          // External link
          href: `mailto:cs@moovup.com?body=%E6%9F%A5%E8%A9%A2%E5%85%A7%E5%AE%B9%EF%BC%9A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A---------------------------------------------------%0D%0AMoovup%E5%8F%83%E8%80%83%E8%B3%87%E6%96%99%20%E8%AB%8B%E5%8B%BF%E5%88%AA%E9%99%A4%0D%0ACompany%20Id: ${this.companyId}%0D---------------------------------------------------`,
          // No need to open email link in new tab
          target: false,
          title: this.$t('link.enquiry'),
        },
        {
          // External link
          href: getLinkByKey(this.currentLocale.code, LinkKeys.privacy),
          title: this.$t('link.privacyPolicy'),
        },
        {
          // External link
          href: getLinkByKey(this.currentLocale.code, LinkKeys.tnc),
          title: this.$t('link.tnc'),
        },
      ]
    },
  },
  data() {
    return {
      /**
       * Event source
       */
      eventSource: '',
      gqlQueries: {
        updateEmployerSetting: UPDATE_EMPLOYER_SETTING,
      },
      gqlUpdateEmployerSettingVariables: {},
    }
  },
  methods: {
    /**
     * Handle header menu item click action
     * based on provided item index
     */
    onItemClick(index) {
      const item = this.items[index]
      if (item) {
        switch (item['data-action']) {
          // Set locale
          case 'locale':
            if (item['data-locale']) {
              this.setAppLocale(item['data-locale'], this.eventSource)
              this.updateEmployerSetting(item['data-locale'])
            }
            break

          // Logout
          case 'logout':
            this.logout(this.eventSource)
            break
        }
      }
    },
    async updateEmployerSetting(locale) {
      this.gqlUpdateEmployerSettingVariables = {
        display_language: locale,
      }

      const res = await this.submitGql('updateEmployerSetting', true)

      if (res && res.data && res.data.update_employer_setting) {
        this.$store.commit(
          'snackbar/setTitle',
          this.$t(`message.${messageKeys.CHANGE_LANGUAGE_SUCCESS}`),
        )
      }
    },
  },
  mixins: [AuthMixin, GraphqlFormMixin],
}
