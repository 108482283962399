<template>
  <header class="hdr-main">
    <section class="hdr-main__container">
      <hdr-main-menu />
      <hdr-main-support />
    </section>
    <span class="section-break" />
  </header>
</template>

<script>
import MainHeaderMenu from './mainHeaderMenu'
import MainHeaderSupportMenu from './mainHeaderSupportMenu'

export default {
  components: {
    'hdr-main-menu': MainHeaderMenu,
    'hdr-main-support': MainHeaderSupportMenu,
  },
}
</script>

<style lang="postcss" scoped>
.hdr-main {
  @apply h-h-app px-4;
}
.hdr-main__container {
  @apply flex flex-row-reverse items-center;
  @apply relative h-full py-1;
}
.hdr-main-menu {
  @apply relative -mr-1 z-1;
}
.hdr-main-support {
  @apply relative z-1;
}
</style>
