// Get reference local storage if available
const storage =
  typeof window !== 'undefined' && typeof window.localStorage !== 'undefined'
    ? window.localStorage
    : null

/**
 * Get value by key from *local storage* if available
 * @param {string} key
 */
export function getLocalStorage(key) {
  if (storage) {
    return storage.getItem(key)
  }
  return null
}

/**
 * Remove value by key from *local storage* if available
 * @param {string} key
 */
export function removeLocalStorage(key) {
  if (storage) {
    return storage.removeItem(key)
  }
  return null
}

/**
 * Set key-value pair to *local storage* if available
 * @param {string} key
 * @param {string} value
 */
export function setLocalStorage(key, value) {
  if (storage) {
    storage.setItem(key, value)
  }
}
