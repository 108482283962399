<template>
  <nav class="side-nav">
    <!-- Mobile nav items -->
    <ol class="side-nav__items side-nav__items--mobile">
      <side-nav-item
        v-for="(item, i) in items.mobile"
        :key="`SidebarNavItemMobile${i}`"
        :active="item.active"
        :icon="item.icon"
        :href="item.href"
        :child-path-pattern="item.childPathPattern"
        :new-feature="item.new"
        :class="`w-1/${items.mobile.length}`"
        :link-path="item.path"
        :link-query="item.query"
        :count="item.count"
        :title="item.title"
      />
    </ol>
    <!-- Desktop nav items -->
    <ol class="side-nav__items side-nav__items--desktop">
      <side-nav-item
        v-for="(item, i) in items.desktop"
        :key="`SidebarNavItemDesktop${i}`"
        :active="item.active"
        :icon="item.icon"
        :href="item.href"
        :child-path-pattern="item.childPathPattern"
        :new-feature="item.new"
        :link-path="item.path"
        :link-query="item.query"
        :count="item.count"
        :title="item.title"
      />
    </ol>
    <div v-if="showODS" class="ods-container">
      <div class="ods">
        <div class="ods__header">
          <span class="ods__title">{{ $t('ods.title') }}</span>
          <span class="ods__badge">{{ $t('ods.badge') }}</span>
        </div>
        <p class="ods__description">
          {{ $t('ods.description') }}
        </p>
        <p class="ods__description">
          {{ $t('ods.description2') }}
        </p>
        <a :href="`${odsUrl}?utm_source=employer_portal&utm_medium=web`" target="_self" class="ods__explore">
          {{ $t('ods.button') }}
          <svg-arrow-right class="arrow-right svg-fill" />
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { getFeatureFlag } from '../../libs/featureFlag'
import SidebarNavItem from './sidebarNavItem'
import SvgArrowRight from '../../assets/svg/arrowRight.svg?inline'
import { getScreenName } from '../../libs/tracking/screenName'

/**
 * Nav item definitions
 */
const items = {
  applications: {
    childPathPattern: '/applications/',
    icon: 'svg-applicant',
    path: '/applications/',
    titleKey: 'applications.title',
  },
  company: {
    childPathPattern: '/profile/',
    icon: 'svg-profile',
    titleKey: 'company.title',
  },
  jobs: {
    childPathPattern: '/jobs/detail/',
    icon: 'svg-job',
    path: '/',
    titleKey: 'jobs.title',
  },
}

export default {
  components: {
    'side-nav-item': SidebarNavItem,
    'svg-arrow-right': SvgArrowRight,
  },
  computed: {
    items() {
      return {
        /**
         * Desktop nav items
         */
        desktop: [
          {
            ...items.jobs,
            active: !this.companySuspended,
            title: this.$t(items.jobs.titleKey),
          },
          {
            ...items.applications,
            active: !this.companySuspended,
            count: this.unreadApplicationCount,
            title: this.$t(items.applications.titleKey),
          },
          {
            ...items.company,
            // Company public profile page
            path: '/profile/public/',
            title: this.$t(items.company.titleKey),
          },
          {
            childPathPattern: '/billing/payment-methods/',
            icon: '',
            path: '/billing/payment-methods/',
            query: {
              event_source: this.eventSource,
            },
            title: this.$t('paymentMethods.title'),
          },
          {
            childPathPattern: '/billing/invoices/',
            icon: '',
            path: '/billing/invoices/',
            title: this.$t('invoice.title'),
          },
        ],

        /**
         * Mobile nav items
         */
        mobile: [
          {
            ...items.jobs,
            active: !this.companySuspended,
            title: this.$t(`${items.jobs.titleKey}Mobile`),
          },
          {
            ...items.applications,
            active: !this.companySuspended,
            count: this.unreadApplicationCount,
            title: this.$t(`${items.applications.titleKey}Mobile`),
          },
          {
            ...items.company,
            // Mobile employer menu
            path: '/profile/',
            title: this.$t(`${items.company.titleKey}Mobile`),
          },
        ],
      }
    },

    unreadApplicationCount() {
      if (!this.unreadCount) {
        return ''
      }
      return this.unreadCount > 99 ? '99+' : `${this.unreadCount}`
    },

  },
  data() {
    return {
      /**
       * Event source
       */
      eventSource: 'nav.menu',
      odsUrl: process.env.EMPLOYER_WEB__ODS_URL,
      // TODO: remove this after QA process in prod is not needed anymore
      showODS:
        (getFeatureFlag('ODS') &&
          this.$store.getters['profile/brApproved'] &&
          !this.$store.getters['profile/companySuspended']) ||
        this.$route.query.enableOds === 'true',
    }
  },
  props: {
    /**
     * Whether company is suspended
     */
    companySuspended: {
      default: false,
      type: Boolean,
    },

    /**
     * Whether company is suspended
     */
    unreadCount: {
      default: null,
      type: Number,
    },
  },
}
</script>

<style lang="postcss" scoped>
.side-nav {
  @apply w-full;
}

@screen mobile {
  .side-nav {
    @apply h-full px-3;
  }
  .side-nav__items {
    @apply flex justify-center;
    @apply w-full max-w-form h-full mx-auto;
  }

  /* Hide desktop nav items */
  .side-nav__items--desktop {
    @apply hidden;
  }
}

@screen desktop {
  .side-nav-item:not(:first-child) {
    @apply mt-2;
  }

  /* hidden the side bar icon on desktop level */
  .side-nav-item::v-deep svg {
    @apply hidden;
  }

  /* Hide mobile nav items */
  .side-nav__items--mobile {
    @apply hidden;
  }
}

.ods-container {
  @apply mt-8 px-4;
}

.ods {
  @apply block w-full p-3 rounded-lg text-left bg-indigo-100 border-indigo-300 border-solid border;
}

.ods__header {
  position: relative;
  @apply flex items-center mb-2 font-bold;
}

.ods__title {
  @apply text-lg;
}

.ods__badge {
  position: absolute;
  right: 0;
  @apply ml-2 px-2 text-xs text-white bg-red-500;
  border-radius: 4px;
}

.ods__description {
  @apply text-sm mb-2 text-grey-500;
}

.ods__explore {
  display: inline-flex;
  @apply bg-indigo-500 p-2 rounded-lg text-white text-sm no-underline font-bold items-center;
}

.arrow-right {
  @apply w-2 h-2 ml-1 mt-1;
}
</style>
