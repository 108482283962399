import { postJSON } from '..'

/**
 * Get *Bearer* type authorization header based on provided token
 * FIXME: extract authorization header logic to *Vuex* store
 * @param {string} token
 */
export function getAuthorizationHeader(token) {
  return token ? { Authorization: `Bearer ${token}` } : {}
}

/**
 * Send POST request to Octo endpoint,
 * return JSON data
 * @param {string} path
 * @param {*} bodyJson
 * @param {string} token
 */
export function postOcto(path, bodyJson, token) {
  const octoUrl = process.env.EMPLOYER_WEB__OCTO_URL || ''
  return postJSON(`${octoUrl}${path}`, bodyJson, getAuthorizationHeader(token), { credentials: process.env.EMPLOYER_WEB__OCTO_CORS_CREDENTIALS || undefined })
}
