import _get from 'lodash/get'
import AuthMixin from '../auth'
import errorKeys from '../../libs/errorKeys'
import FormMixin from '.'
import { postEmployerGQL } from '../../api/octo/graphql'

/**
 * Mixin for forms connecting to *graphql* endpoint
 */
export default {
  computed: {
    /**
     * Company contact email form *Vuex*
     */
    companyEmail() {
      return this.$store.getters['profile/companyEmail']
    },

    /**
     * Company ID from *Vuex*
     */
    companyID() {
      return this.$store.getters['profile/companyID']
    },

    /**
     * Returns graphql variables
     * based on current operation
     */
    gqlVariables() {
      return {}
    },

    /**
     * Whether company has payment method, from *Vuex*
     */
    hasPaymentMethod() {
      return this.$store.getters['profile/hasPaymentMethod']
    },
  },
  data() {
    return {
      /**
       * Current graphql operation name
       */
      gqlOperation: 'default',

      /**
       * Graphql queries,
       * supports multiple operations in one form
       */
      gqlQueries: {
        default: '',
      },
    }
  },
  methods: {
    /**
     * Wrapper function for submitting form
     * as *graphql* request,
     * handles graphql errors
     * @param {string} operation
     */
    async submitGql(operation, skipValidation = false) {
      // Set operation name if provided
      if (operation) {
        this.gqlOperation = operation
      }

      // Execute submit function and await for result
      const res = await this.submitForm(this.submitGqlFunc, {
        skipValidation,
      })

      const errorCode = _get(
        res,
        'error.payload.errors[0].extensions.error_code',
      )
      if (errorCode) {
        // Map first graphql error code into message
        this.formError = errorKeys[`E${errorCode}`]
      }

      return res
    },

    /**
     * Submit graphql request to
     * Octo employer *graphql* endpoint
     */
    submitGqlFunc() {
      const query = this.gqlQueries[this.gqlOperation]
      if (query) {
        return postEmployerGQL(query, this.gqlVariables, this.token)
      }
    },
  },
  mixins: [AuthMixin, FormMixin],
}
