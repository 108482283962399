<template>
  <header class="side-hdr">
    <svg-moovup-logo class="side-hdr__logo" />
  </header>
</template>

<script>
import SvgMoovupLogo from '../../assets/svg/moovupLogo_v4_red.svg?inline'

export default {
  components: {
    'svg-moovup-logo': SvgMoovupLogo,
  },
}
</script>

<style lang="postcss" scoped>
.side-hdr {
  @apply flex justify-center items-center w-full h-h-app;
}

.side-hdr__logo {
  width: 175px;
}
</style>
