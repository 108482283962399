<template>
  <button class="b b--hdr_menu" tabindex="0" :title="$t('header.accountTitle')">
    <template v-if="companyName">
      <!-- Company logo -->
      <logo :name="companyName" :url="companyLogoDisplayURL" />
      <!-- Company name -->
      <h3 class="b__title">{{ companyName }}</h3>
    </template>
    <!-- Drop down arrow -->
    <svg-arrow-down class="b__arrow" />
  </button>
</template>

<script>
import { fileUploadReadyDelay } from '../../api/s3'
import Logo from '../logo'
import SvgArrowDown from '../../assets/svg/arrowDown.svg?inline'

export default {
  components: {
    logo: Logo,
    'svg-arrow-down': SvgArrowDown,
  },
  computed: {
    /**
     * Company logo URL
     */
    companyLogoURL() {
      return this.$store.getters['profile/companyLogo'] || ''
    },

    /**
     * Company name
     */
    companyName() {
      return this.$store.getters['profile/companyName'] || ''
    },
  },
  data() {
    return {
      /**
       * Displayed company logo URL
       */
      companyLogoDisplayURL: this.$store.getters['profile/companyLogo'] || '',
    }
  },
  watch: {
    /**
     * Delay changing displayed logo URL
     * on *vuex* value change
     */
    companyLogoURL(newVal) {
      setTimeout(() => {
        this.companyLogoDisplayURL = newVal
      }, fileUploadReadyDelay)
    },
  },
}
</script>

<style lang="postcss" scoped>
.b.b--hdr_menu {
  @apply flex items-center px-3 bg-grey-50;
}
.logo {
  @apply mr-2;
}
.b__title {
  @apply text-left truncate;
  max-width: 200px;
  line-height: 26px;
}
.b__arrow {
  @apply w-b-sm h-b-sm ml-1 -mr-1;
  transform-origin: center center;
  transition: transform 0.2s ease;
}
</style>
