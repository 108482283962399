/**
 * Set value by key to *cookies* if available
 * @param {string} key
 * @param {string} value
 * @param {number} expiredDayAfter
 * @param {boolean} secure
 */
export function setCookie(key, value, expiredDayAfter, secure = true) {
  const d = new Date()
  d.setTime(d.getTime() + expiredDayAfter * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  let cookie = key + '=' + value + ';' + expires + ';path=/'
  if (secure) {
    cookie += ';Secure'
  }
  document.cookie = cookie
}

/**
 * Get value by key from *cookies* if available
 * @param {string} key
 */
export function getCookie(key) {
  const name = key + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
