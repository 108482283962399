<template>
  <div class="logo" :class="!url ? 'logo--avatar' : ''" @click="click">
    <!-- Logo image -->
    <img
      v-if="url"
      loading="lazy"
      :alt="name || 'Logo'"
      :src="url"
      :title="name || 'Logo'"
    />
    <!-- No logo background -->
    <svg-avatar v-show="!url" class="svg-fill" />
  </div>
</template>

<script>
import SvgAvatar from '../assets/svg/avatar.svg?inline'

export default {
  components: {
    'svg-avatar': SvgAvatar,
  },
  methods: {
    /**
     * click event
     */
    click() {
      this.$emit('click')
    },
  },
  props: {
    /**
     * Logo name
     */
    name: {
      default: '',
      type: String,
    },

    /**
     * Logo URL
     */
    url: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="postcss" scoped>
.logo {
  @apply rounded-full bg-white overflow-hidden;
  @apply border border-solid border-pink-medium;
  width: 34px;
  height: 34px;

  & img {
    @apply w-full h-full object-cover;
  }
  & svg {
    @apply w-full h-full text-white;
  }

  &.logo--avatar {
    @apply bg-pink-disabled;
  }
}
</style>
