<template>
  <section class="not-found">
    <div class="not-found__msg">
      <h1>{{ $t('notFound.title') }}</h1>
      <p>{{ $t('notFound.content') }}</p>
    </div>
    <b-main link-path="/" :title="backTitle" />
  </section>
</template>

<script>
import MainButton from './button/mainButton'

export default {
  components: {
    'b-main': MainButton,
  },
  computed: {
    /**
     * Back button title
     */
    backTitle() {
      return `${this.$t('general.backTo')}${this.$t('homepage.name')}`
    },
  },
}
</script>

<style lang="postcss" scoped>
.not-found {
  @apply flex flex-col items-start;
  @apply w-full mx-auto;

  & h1 {
    @apply font-medium;
  }
  & p {
    @apply mt-2 text-sm;
  }
}

@screen mobile {
  .not-found {
    @apply max-w-form px-4 py-8;

    & h1 {
      @apply text-xl;
    }
  }
  .b.b--main {
    @apply mt-6;
  }
}

@screen desktop {
  .not-found {
    @apply max-w-form-app-desktop py-10;
    padding-left: 3.5rem;
    padding-right: 3.5rem;

    & h1 {
      @apply text-2xl;
    }
  }
  .b.b--main {
    @apply mt-8;
  }
}
</style>
