<template>
  <component
    :is="buttonComponent"
    class="b b--icon"
    tabindex="0"
    v-bind="buttonProps"
    @click="onClick"
  >
    <slot name="icon" />
  </component>
</template>

<script>
import ButtonMixin from '../../mixins/button'

export default {
  mixins: [ButtonMixin],
}
</script>

<style lang="postcss" scoped>
.b.b--icon {
  @apply w-full h-full p-1;

  & >>> svg {
    @apply w-full h-full;
  }
}
</style>
