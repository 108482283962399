<template>
  <div class="m m--dropdown" :class="!active ? 'm--inactive' : ''">
    <!-- Menu toggle button -->
    <div class="m__button">
      <slot name="button" />
    </div>
    <!-- Menu list -->
    <div class="m__list">
      <m-list popup :items="items" @click="onItemClick" />
    </div>
  </div>
</template>

<script>
import ListMenu from '../menu/listMenu'

export default {
  components: {
    'm-list': ListMenu,
  },
  methods: {
    /**
     * Forward menu item click event
     * to parent component
     */
    onItemClick(index) {
      this.$emit('click', index)
    },
  },
  props: {
    /**
     * Whether menu is active
     */
    active: {
      default: true,
      type: Boolean,
    },

    /**
     * Menu items
     */
    items: {
      default: () => [],
      type: Array,
    },
  },
}
</script>

<style lang="postcss" scoped>
.m.m--dropdown {
  @apply relative;

  &:not(:hover) .m__list {
    @apply opacity-0 pointer-events-none;
    transform: translateY(-3rem);
  }
}
.m__button {
  @apply relative z-1;
}
.m__list {
  @apply absolute left-0 w-full pt-2 z-0;
  top: 100%;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

/* Inactive menu */
.m.m--dropdown.m--inactive {
  @apply pointer-events-none;
}
</style>
