/**
 * NuxtJS middleware to get and switch to the locale in the query string of URL
 */
export default function ({ route, app }) {
  const locales = app.i18n.locales.map((locale) => locale.code)
  const queryLocale = route.query.locale
  if (queryLocale && locales.includes(queryLocale)) {
    app.i18n.setLocale(queryLocale)
  }
}
