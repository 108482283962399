import LogoutMixin from './logout'

/**
 * Mixin for authentication-related utilities
 */
export default {
  computed: {
    /**
     * Logged in flag from *Vuex* store
     */
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },

    /**
     * Employer token from *Vuex* store
     */
    token() {
      return this.$store.state.auth.token
    },
  },
  mixins: [LogoutMixin],
}
